import React, { useEffect, useState } from "react";
import { InputGroup } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { modalActions } from "../store/modal";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../Firebase/Firebase";
import '../../styles/Signin.css'
import BrandsTable from "./BrandsTable";
import AlertAddBrandModal from "../Modals/AddBrandModal";

const Brands = () => {
  const dispatch = useDispatch();
  const [brands, setBrands] = useState([]);
  const [brandsId, setBrandsId] = useState("");
  const [dataApi, setDataApi] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const getBrandDocument = async () => {
    try {
      const brandsRef = doc(db, 'masters', 'brands');
      const brandsDoc = await getDoc(brandsRef);
      if (brandsDoc.exists()) {
        const brandsData = brandsDoc.data();
        const { BRANDS } = brandsData;

        setBrands(BRANDS);
        setBrandsId(brandsDoc.id)
        setDataApi(BRANDS);
      }
      else {
        await setDoc(brandsRef, {
          BRANDS: []
        })
        setBrandsId(brandsDoc.id);
      }
    }
    catch (error) {
      console.log(error);
    }

  }
  useEffect(() => {
    getBrandDocument()
  }, [])


  const handleSearch = (e) => {
    if (e.target.value === '') {
      setBrands(dataApi);
    }
    else {
      const lowerCaseSearchTerm = searchTerm.trim().toLowerCase();

      const filterData = dataApi.filter((item) => {
        const date = new Date(item.updatedAt.seconds * 1000 + item.updatedAt.nanoseconds / 1000000);
        // console.log(date);
        const formattedDate = date.toLocaleDateString().split('/').join('-');
        const formattedTime = date.toLocaleTimeString('en-US', { hour12: false });

        let brandName = item.name || '';
        let createdBrandAtDate = formattedDate || ''
        let createdBrandAtTime = formattedTime || ''
        let uploaded_by = item.uploadedBy || ''

        return (
          brandName.toLowerCase().includes(lowerCaseSearchTerm) ||
          createdBrandAtDate.toLowerCase().includes(lowerCaseSearchTerm) ||
          createdBrandAtTime.toLowerCase().includes(lowerCaseSearchTerm) ||
          uploaded_by.toLowerCase().includes(lowerCaseSearchTerm)
        )

      });
      // console.log(filterData);
      setBrands(filterData);
    }
    setSearchTerm(e.target.value);
  }

  return (
    <React.Fragment>
      <div
        style={{
          position: 'fixed',
          top: 110,
          left: 240,
          width: '82%',
          height: '100%',
          overflowY: 'scroll'
        }}
      >
        <div className="manage-templates-header" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '2rem', alignItems: 'center' }}>
          <span className="manage-templates-text">Manage Brand</span>
          <span className="add-templates-header" onClick={() => dispatch(modalActions.toggleAddBrandModalHandler())} >Add Brand <span className="plus">+</span></span>
        </div>

        <InputGroup>
          <input type="search" id="input-placeholder" autoComplete="off" placeholder="Search by brand name, date, time, uploaded by" value={searchTerm}
            onChange={(e) => handleSearch(e)}
            className="form-control" style={{
              padding: '10px',
              background: '#FFFFFF',
              boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.2)'
            }} />
          <FiSearch
            style={{
              position: 'absolute',
              top: '50%',
              left: '10px',
              transform: 'translateY(-50%)',
              pointerEvents: 'cursor'
            }}
          />
        </InputGroup>

        <div style={{ marginTop: '2rem' }}>
          <BrandsTable brands={brands} setBrands={setBrands} brandsId={brandsId} setDataApi={setDataApi} dataApi={dataApi} setSearchTerm={setSearchTerm} />
        </div>
      </div>
      <AlertAddBrandModal brands={brands} setBrands={setBrands} brandsId={brandsId} setDataApi={setDataApi} />
    </React.Fragment>
  )
}
export default Brands;
