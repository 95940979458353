import { createSlice } from "@reduxjs/toolkit";

const templateSlice = createSlice({
    name: 'template',
    initialState: {
        templateData: null,
         
    },
    reducers:{
        setTemplatesData: (state, action)=>{
            // state.templateData = action.payload;
        },
    }
})
export const templateActions = templateSlice.actions;
export default templateSlice.reducer;