import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "../store/modal";
import { db, storage } from "../Firebase/Firebase";
import { deleteDoc,doc, collection, getDocs, where, query } from "firebase/firestore";
import {ref,listAll, deleteObject } from 'firebase/storage';
import { toast } from "react-toastify";
import { updateTemplatesCollection } from "../HomePage/ManageTemplates/UpdateTemplates";


const DeleteProductModalOpen = (props)=>{
    const isProductDeleteModalOpen = useSelector((state)=> state.modal.isProductDeleteModalOpen);
    const dispatch = useDispatch();
    const {selectedRow, setSelectedRow, products, setProducts, setDataApi, dataApi, setSearchTerm} = props;
    const handleProductTemplate = async (selectedRow)=>{
      const q = query(collection(db, "templates"), where('product', '==', selectedRow.name));
            try{
              let querySnapshot =  await getDocs(q);

                  querySnapshot.forEach((doc)=>{
                     
                     doc.data().images.forEach((image)=>{
                        deleteImageByUrl(image.url)
                    })
                    deleteTemplatesDoc(doc.id)
                  })
            }
            catch(error){
              console.log(error);
            }
          
    }
    const deleteTemplatesDoc = async (id)=>{
          await deleteDoc(doc(db, 'templates', id))
    }

     // delete Images
     const deleteImageByUrl = async (imageUrl) => {
      try {
        // Extract the image path from the URL
        const imagePath = getImagePathFromUrl(imageUrl);
        // console.log(imagePath, 'image path');
        // Get a reference to the image file
        const imageRef = ref(storage, imagePath)
        // console.log(imageRef, 'image reference');
        // console.log(imageRef.fullPath, 'folder  path');
        const folderPath = imageRef.fullPath;
        

        const folderRef = ref(storage, folderPath);

        // Get a list of files within the folder
        const fileList = await listAll(folderRef);

        // Delete each file within the folder
        const deleteFilePromises = fileList.items.map((file) =>
          deleteObject(ref(storage, `${folderPath}/${file.name}`))
        );
        await Promise.all(deleteFilePromises);

        // Delete the empty folder
        await deleteObject(folderRef);
        // Delete the image file
        // await imageRef.delete();
    
        // console.log('Image deleted successfully.');
      } catch (error) {
        console.error('Error deleting image:', error);
      }
    };
    function getImagePathFromUrl(imageUrl) {
      console.log(imageUrl, 'image url');
      // Split the URL by the bucket URL
      const parts = imageUrl.split('https://storage.googleapis.com/');
      // console.log(parts, 'parts');
    
      // Extract the path after the bucket URL
      const path = parts[0];
      // console.log(path, 'path');
      // Return the image path
      return path;
    }
    
    const handleDeleteDoc = async()=>{
              try{
                const newData = dataApi.filter(item => item.id !== selectedRow.id);
                await deleteDoc(doc(db, "products", selectedRow.id));
                await handleProductTemplate(selectedRow)
                dispatch(modalActions.toggleProductDeleteModalHandler());
                updateTemplatesCollection()
                setProducts(newData);
                setDataApi(newData)
                setSelectedRow(null);
                setSearchTerm('');
                toast.success("Delete Successfully");
              }
              catch(error){
                console.log(error);
              }
    }
      
    return (
        <>
             <Modal
             centered
             show={isProductDeleteModalOpen}
             onHide={()=> dispatch(modalActions.toggleProductDeleteModalHandler())}
           >
             <Modal.Header closeButton></Modal.Header>
             <Modal.Body style={{textAlign:'center'}}>
                Are you sure want to delete ?
             </Modal.Body>
             <Modal.Footer>
               <Button variant="primary" 
               onClick={handleDeleteDoc}
               >Confirm</Button>
               <Button variant="primary" 
               onClick={()=> dispatch(modalActions.toggleProductDeleteModalHandler())}
               >Cancel</Button>

             </Modal.Footer>
           </Modal>
          
        </>
    )
}
export default DeleteProductModalOpen;