import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from '../store/modal'
import { useEffect, useState } from "react";
import {db} from '../Firebase/Firebase';
import {collection, addDoc, Timestamp, updateDoc, doc, getDoc, getDocs} from "firebase/firestore";
import '../../styles/Signin.css';
import { toast } from "react-toastify";      
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { updateTemplatesCollection } from "../HomePage/ManageTemplates/UpdateTemplates";
import { v4 } from "uuid";

const filter = createFilterOptions();

const AlertAddProductModal = (props)=>{

    const {products, setProducts,mmdProduct, brandsData, setBrandsData, categoriesData, marketsData, typeData, subcategoriesData, statusData, tag, setCategoriesData, setMarketsData, setSubcategoriesData, setStatusData, setTypeData, setDataApi} = props;

    const dispatch = useDispatch();
    const isAddProductModalOpen = useSelector(state => state.modal.isAddProductModalOpen);
    const [uploadedBy, setUploadedBy] = useState("");
    const [productsName, setProductsName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [selectBrands, setSelectBrands] = useState(null);
    const [createBrand, setCreateBrand] = useState('');
    const [selectCategories, setSelectCategories] = useState(null);
    const [createCategory, setCreateCategory] = useState('');
    const [selectMarket, setSelectMarket] = useState(null);
    const [createMarket, setCreateMarket] = useState('');
    const [selectType, setSelectType] = useState(null);
    const [createType, setCreateType] = useState('');
    const [selectSubCategories, setSelectSubCategories] = useState(null);
    const [createSubCategory, setCreateSubCategory] = useState('');
    const [selectStatus, setSelectStatus] = useState(null);
    const [createStatus, setCreateStatus] = useState('');
    const [selectMmdProduct, setSelectMmdProduct] = useState('');
    const [selectProductCompetitor, setSelectProductCompetitor] = useState('');
    const [selectTag, setSelectTag] = useState('');
    const [mmd, setMmd] = useState(false);
    const [error, setError] = useState({
          errorProduct: false,
          errorCompetitor: false,
          errorBrand: false,
          errorCategory: false,
          errorMarket: false,
          errorStatus: false,
          errorSubcategory: false,
          errorType: false
    });
    const [errorMessage, setErrorMessage] = useState({
          errorProduct: 'Please Enter Product Name',
          errorCompetitor: 'Please Select Competitor.',
          errorBrand: 'Please Select Brand.',
          errorCategory: 'Please Select Category.',
          errorMarket: 'Please Select Market',
          errorStatus: 'Please select Status',
          errorSubcategory: 'Please Select Subcategory',
          errorType: 'Please Select Type'
    });

    // handle product

    const handleChangeProductName = (event)=>{
            setProductsName(event.target.value);
            setError({...error, errorProduct: false});
            setErrorMessage({...errorMessage, errorProduct: ''});
        }

      // handle brand

      const handleChangeBrand = (event, newValue) => {
        // console.log(newValue, 'new Value');
        if (typeof newValue === 'string') {
          setSelectBrands({
            name: newValue,
          });
          }
          else if (newValue && newValue.inputValue) {
          setCreateBrand(newValue.inputValue);
          setSelectBrands({
            name: newValue.inputValue,
          });
         }
         else{
          setSelectBrands(newValue);
         }
      };
      
      const handleInputChangeBrand = (event, newInputValue) => {
        // console.log(newInputValue, 'Input Change new input value');
        setSelectBrands({
          name: newInputValue,
        });
        // console.log(selectBrands, ' Input Change brand value');

        setError({...error, errorBrand: false});
        setErrorMessage({...errorMessage, errorBrand: ''});
      };

      const updateBrandsDocument =  async (newArray)=>{
        const brandsRef = doc(db, 'masters', 'brands');
        const brandsDoc =  await getDoc(brandsRef);
        if(brandsDoc.exists()){
          await updateDoc(brandsRef, {
                BRANDS: newArray
          })
          setBrandsData(newArray);
        }
        else{
          console.log('document exists');
        }
      }
       
    const addNewBrand = ()=>{
         if(createBrand){
          const newData = {
            name: createBrand,
            uploadedBy: uploadedBy,
            createdAt: Timestamp.fromDate(new Date()),
            updatedAt: Timestamp.fromDate(new Date()),
            id: v4()
           }
           const newArray = [...brandsData, newData]
           updateBrandsDocument(newArray);
          }
       }

    // handle category
    const handleChangeCategory = (event, newValue) => {
      if (typeof newValue === 'string') {
        setSelectCategories({
          name: newValue,
        });
      } 
      else if (newValue && newValue.inputValue) {
        // Create a new value from user input
        setCreateCategory(newValue.inputValue);
        setSelectCategories({
          name: newValue.inputValue,
        });
      }
      else {
        setSelectCategories(newValue);
      }
    };
    
    const handleInputChangeCategory = (event, newInputValue) => {
      setSelectCategories({
        name: newInputValue,
      });
      setError({...error, errorCategory: false});
      setErrorMessage({...errorMessage, errorCategory: ''});
    };

    const updateCategoriesDocument =  async (newArray)=>{
      const categoriesRef = doc(db, 'masters', 'categories');
      const categoriesDoc =  await getDoc(categoriesRef);
      if(categoriesDoc.exists()){
        await updateDoc(categoriesRef, {
              CATEGORIES: newArray
        })
        setCategoriesData(newArray);
      }
      else{
        console.log('document exists');
      }
    }
     
  const addNewCategory = ()=>{
       if(createCategory){
        const newData = {
          name: createCategory,
          uploadedBy: uploadedBy,
          createdAt: Timestamp.fromDate(new Date()),
          updatedAt: Timestamp.fromDate(new Date()),
          id: v4()
         }
         const newArray = [...categoriesData, newData]
         updateCategoriesDocument(newArray);
        }
     }

    // handle market
    const handleChangeMarket = (event, newValue) => {
      if (typeof newValue === 'string') {
        setSelectMarket({
          name: newValue,
        });
      } else if (newValue && newValue.inputValue) {
        // Create a new value from user input
        setCreateMarket(newValue.inputValue);
        setSelectMarket({
          name: newValue.inputValue,
        });
      } 
      else {
        setSelectMarket(newValue);
      }
    };
    
    const handleInputChangeMarket = (event, newInputValue) => {
      setSelectMarket({
        name: newInputValue,
      });
      setError({...error, errorMarket: false});
      setErrorMessage({...errorMessage, errorMarket: ''});
    };

    const updateMarketDocument =  async (newArray)=>{
      const marketsRef = doc(db, 'masters', 'markets');
      const marketsDoc =  await getDoc(marketsRef);
      if(marketsDoc.exists()){
        await updateDoc(marketsRef, {
              MARKETS: newArray
        })
        setMarketsData(newArray);
      }
      else{
        console.log('document exists');
      }
    }
     
  const addNewMarket = ()=>{
       if(createMarket){
        const newData = {
          name: createMarket,
          uploadedBy: uploadedBy,
          createdAt: Timestamp.fromDate(new Date()),
          updatedAt: Timestamp.fromDate(new Date()),
          id: v4()
         }
         const newArray = [...marketsData, newData]
         updateMarketDocument(newArray);
        }
     }

    // handle status
    const handleChangeStatus = (event, newValue) => {
      if (typeof newValue === 'string') {
        setSelectStatus({
          name: newValue,
        });
      } 
      else if (newValue && newValue.inputValue) {
        // Create a new value from user input
        setCreateStatus(newValue.inputValue);
        setSelectStatus({
          name: newValue.inputValue,
        });
      } else {
        setSelectStatus(newValue);
      }
    };
    
    const handleInputChangeStatus = (event, newInputValue) => {
      setSelectStatus({
        name: newInputValue,
      });
      setError({...error, errorStatus: false});
      setErrorMessage({...errorMessage, errorStatus: ''})
    };

    const updateStatusDocument =  async (newArray)=>{
      const statusRef = doc(db, 'masters', 'status');
      const statusDoc =  await getDoc(statusRef);
      if(statusDoc.exists()){
        await updateDoc(statusRef, {
              STATUS: newArray
        })
        setStatusData(newArray);
      }
      else{
        console.log('document exists');
      }
    }
     
  const addNewStatus = ()=>{
       if(createStatus){
        const newData = {
          name: createStatus,
          uploadedBy: uploadedBy,
          createdAt: Timestamp.fromDate(new Date()),
          updatedAt: Timestamp.fromDate(new Date()),
          id: v4()
         }
         const newArray = [...statusData, newData]
         console.log(newArray);
         updateStatusDocument(newArray);
        }
     }

    // handle subcategory
    const handleChangeSubcategory = (event, newValue) => {
      if (typeof newValue === 'string') {
        setSelectSubCategories({
          name: newValue,
        });
      } else if (newValue && newValue.inputValue) {
        // Create a new value from user input
        setCreateSubCategory(newValue.inputValue);
        setSelectSubCategories({
          name: newValue.inputValue,
        });
      } else {
        setSelectSubCategories(newValue);
      }
    };
    
    const handleInputChangeSubcategory = (event, newInputValue) => {
      console.log(newInputValue);
      setSelectSubCategories({
        name: newInputValue,
      });
      setError({...error, errorSubcategory: false});
      setErrorMessage({...errorMessage, errorSubcategory: ''})
    };

    const updateSubcategoriesDocument =  async (newArray)=>{
      const subcategoriesRef = doc(db, 'masters', 'subcategories');
      const subcategoriesDoc =  await getDoc(subcategoriesRef);
      if(subcategoriesDoc.exists()){
        await updateDoc(subcategoriesRef, {
              SUBCATEGORIES: newArray
        })
        setSubcategoriesData(newArray);
      }
      else{
        console.log('document exists');
      }
    }
     
  const addNewSubcategory = ()=>{
       if(createSubCategory){
        const newData = {
          name: createSubCategory,
          uploadedBy: uploadedBy,
          createdAt: Timestamp.fromDate(new Date()),
          updatedAt: Timestamp.fromDate(new Date()),
          id: v4()
         }
         const newArray = [...subcategoriesData, newData]
         console.log(newArray);
         updateSubcategoriesDocument(newArray);
        }
     }

    // handle type
    
    const handleChangeType = (event, newValue) => {
      if (typeof newValue === 'string') {
        setSelectType({
          name: newValue,
        });
      } 
      else if (newValue && newValue.inputValue) {
        // Create a new value from user input
        setCreateType(newValue.inputValue);
        setSelectType({
          name: newValue.inputValue,
        });
      }
      else {
        setSelectType(newValue);
      }
    };
    
    const handleInputChangeType = (event, newInputValue) => {
      console.log(newInputValue);
      setSelectType({
        name: newInputValue,
      });
      setError({...error, errorType: false});
      setErrorMessage({...errorMessage, errorType: ''})
    };

    const updateTypeDocument =  async (newArray)=>{
      const typeRef = doc(db, 'masters', 'type');
      const typeDoc =  await getDoc(typeRef);
      if(typeDoc.exists()){
        await updateDoc(typeRef, {
              TYPES: newArray
              
        })
        setTypeData(newArray);
      }
      else{
        console.log('document exists');
      }
    }
     
  const addNewType = ()=>{
       if(createType){
        const newData = {
          name: createType,
          uploadedBy: uploadedBy,
          createdAt: Timestamp.fromDate(new Date()),
          updatedAt: Timestamp.fromDate(new Date()),
          id: v4()
         }
         const newArray = [...typeData, newData]
         console.log(newArray);
         updateTypeDocument(newArray);
         
        }
     }
     
  const handleMmdProduct = (e, value)=>{
    const productCompetitor = value;
    const string = value;
    const lastIndex = string.lastIndexOf("-");
    const mmdProductName = string.substring(0, lastIndex).trim();
          setSelectMmdProduct(mmdProductName);
          setSelectProductCompetitor(productCompetitor);
  }
  const handleInputChangeCompetitor = (e, newValue)=>{
        if(newValue === ''){
           setSelectProductCompetitor('');
           setSelectMmdProduct('');
        }
        
  }
  const handleOnKeyPress = (event)=>{
    if(selectProductCompetitor){
       event.preventDefault();
    }
  }

  const handleOnKeyDown = (event)=>{
    if(selectProductCompetitor){
      event.preventDefault();
    }
  }
  const handleInputChangeTag = (event, newInputValue)=>{ 
        setSelectTag(newInputValue);

        // console.log(newInputValue, 'new Input value');
        if(selectTag === 'others' || newInputValue === 'others' || newInputValue === ''){
          setSelectProductCompetitor('');
        }
        setError({...error, errorCompetitor: false});
        setErrorMessage({...errorMessage, errorCompetitor: ''})
  }
  const isDisabled = selectTag === 'others' || selectTag === '';

    useEffect(()=>{
      const getName = localStorage.getItem("Display Name");
            setUploadedBy(getName);
            
    },[])
    
    const handleOnHide = ()=>{
      dispatch(modalActions.toggleAddProductModalHandler());
      setSelectBrands(null);
      setSelectCategories(null);
      setSelectMarket(null);
      setSelectStatus(null);
      setSelectSubCategories(null);
      setSelectType(null);
      setSelectProductCompetitor('');
      setSelectTag('');
      setProductsName('');
    }

     // Reference refer to the collection products

    const collectionRefForProducts = collection(db, "products");
    // add data
    const addNewProduct = async ()=>{
      
      if(products.some(element=> element.name === productsName.charAt(0).toUpperCase() + productsName.slice(1))){
        alert('Please enter unique product name ');
        setProductsName('')
        return;
        }
      else if(productsName.trim() === ''){
          setError({...error, errorProduct: true});
          setErrorMessage({...errorMessage, errorProduct: 'Please Enter Product Name'})
          return;
      }
      else if(!selectTag || tag.includes(selectTag) === false){
        setError({...error, errorCompetitor: true});
        setErrorMessage({...errorMessage, errorCompetitor: 'Please Select Competitor'});
        return;
      }
      else if(selectBrands === null || selectBrands.name === ''){
        setError({...error, errorBrand: true});
        setErrorMessage({...errorMessage, errorBrand: 'Please Select Brand'})
        return;
      }
      else if(selectCategories === null || selectCategories.name === ''){
        setError({...error, errorCategory: true});
        setErrorMessage({...errorMessage, errorCategory: 'Please Select Category'})
        return;
      }
      else if(selectMarket === null || selectMarket.name === ''){
        setError({...error, errorMarket: true});
        setErrorMessage({...errorMessage, errorMarket: 'Please Select Market'})
        return;
      }
      else if(selectStatus === null || selectStatus.name === ''){
        setError({...error, errorStatus: true});
        setErrorMessage({...errorMessage, errorStatus: 'Please Select Status'})
        return;
      }
      else if(selectType === null || selectType.name === ''){
        setError({...error, errorType: true});
        setErrorMessage({...errorMessage, errorType: 'Please Select Type'})
        return;
      }
      else if(selectSubCategories === null || selectSubCategories.name === ''){
        setError({...error, errorSubcategory: true});
        setErrorMessage({...errorMessage, errorSubcategory: 'Please Select Subcategory'})
        return;
      }
      else{
            const dataStructure = {
            name: productsName.charAt(0).toUpperCase() + productsName.slice(1),
            uploadedBy: uploadedBy,
            product_version: [],
            barcode: '8908001588331',
            code: 'BAT0001',
            mmd: mmd,
            brand: selectBrands ? selectBrands.name : '',
            category: selectCategories ? selectCategories.name : '',
            market: selectMarket ? selectMarket.name : '',
            status:selectStatus ? selectStatus.name : '',
            subcategory: selectSubCategories ? selectSubCategories.name : '',
            type: selectType ? selectType.name : '',
            createdAt: Timestamp.fromDate(new Date()),
            updatedAt: Timestamp.fromDate(new Date()),
            product_competitor: selectTag === 'competitor' ? selectMmdProduct : '' ,
            tag: selectTag
            }  
            setIsLoading(true);
            try{
              await addDoc(collectionRefForProducts, {...dataStructure});
              getProductsData();
              setIsLoading(false);
              toast.success("Product Added Successfully");
              dispatch(modalActions.toggleAddProductModalHandler());
              addNewBrand();
              addNewCategory();
              addNewMarket();
              addNewStatus();
              addNewSubcategory();
              addNewType();
              updateTemplatesCollection();
              setProductsName("");
              setSelectBrands(null);
              setSelectCategories(null);
              setSelectMarket(null);
              setSelectStatus(null);
              setSelectSubCategories(null);
              setSelectType(null);
              setSelectTag('');
              setSelectProductCompetitor('');
            }
            catch(error){
              console.log(error);
            }
      }
  }
  const getProductsData = async()=>{
    try{
      const querySnapshot = await getDocs(collection(db, "products"));
      const newData = [];
      querySnapshot.forEach(doc => {
        newData.push({...doc.data(), id:doc.id});
         
        })
      setProducts(newData);
      setDataApi(newData);
      }
      catch(error){
      console.log(error);
      }
   }
   
    return(
        <Modal 
            size="xl"
            centered
            show={isAddProductModalOpen} 
            onHide={handleOnHide}
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body style={{padding: '2rem'}}>
                <p className="add-template">Add Product</p>
                <div style={{display: 'flex', justifyContent:'space-between'}}>
                  <span className="product-label">Product Name </span>
                  <span style={{marginLeft:'-20px'}}>
                      <TextField
                      style={{
                        width:'323px'
                      }}
                      error={error.errorProduct}
                      id="outlined-error"
                      label="Enter Product Name"
                      value={productsName}
                      onChange={handleChangeProductName}
                      helperText={error.errorProduct ? errorMessage.errorProduct : ''}
                    />
                  </span>
                 <div style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    marginBottom: '2rem'
                   }}>
                      <span className="select-label"> Select Competitor / Others
                          </span>
                          <span style={{
                                marginLeft: '35px'
                          }}>
                            <Stack spacing={2} sx={{ 
                                width: '320px',
                                background: '#FFFFFF',
                                borderRadius: '5px',
                              }}>
                                  <Autocomplete
                                    freeSolo
                                    id="free-solo-2-demo"
                                    disableClearable
                                    value={selectTag}
                                    // onChange={handleTag}
                                    onInputChange={handleInputChangeTag}

                                    options={tag.map((option) =>option)}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Search Competitor / Others"
                                        InputProps={{
                                          ...params.InputProps,
                                          type: 'search',
                                        }}
                                        variant="outlined"
                                        error={error.errorCompetitor}
                                        helperText={error.errorCompetitor ? errorMessage.errorCompetitor: ''}
                                      />
                                    )}
                                  />
                            </Stack>
                      </span>
                   </div>
                </div>
                <div style={{
                  display:'flex',
                  justifyContent:'flex-start',
                  marginBottom:'2rem'
                }}>
                    <span className="select-label"> Select Competitor Product
                      </span>
                      <span style={{
                            marginLeft: '35px'
                      }}>
                        <Stack spacing={2} sx={{ 
                            width: '320px',
                            background: '#FFFFFF',
                            borderRadius: '5px',
                          }}>
                              <Autocomplete
                                style={{
                                width: '830px',
                                }}
                                freeSolo
                                id="free-solo-2-demo"
                                disabled={isDisabled}
                                value={selectProductCompetitor}
                                disableClearable
                                onChange={handleMmdProduct}
                                onInputChange={handleInputChangeCompetitor}
                                options={mmdProduct.map((option) =>  option ? `${option.name} - ${option.id}`: '')}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Search MMD Product"
                                    InputProps={{
                                      ...params.InputProps,
                                      type: 'search',
                                      style: { cursor: 'default' },
                                      onKeyDown: handleOnKeyDown,
                                      onKeyPress: handleOnKeyPress
                                    }}
                                  />
                                )}
                              />
                            </Stack>
                      </span>
                </div>
                <div style={{display: 'flex', justifyContent:'space-between'}}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    marginBottom: '2rem'

                   }}>
                      <span className="select-label">Select Brand
                      </span>
                      <span style={{
                            marginLeft: '35px'
                      }}>
                        <Stack spacing={2} sx={{ 
                            width: '320px',
                            background: '#FFFFFF',
                            borderRadius: '5px',
                          }}>
                              <Autocomplete
                                    style={{
                                    width: '323px',
                                    }}
                                    // value={selectBrands}
                                    onChange= {handleChangeBrand}
                                    disableClearable
                                    onInputChange={handleInputChangeBrand}
                                    filterOptions={(options, params) => {
                                      const filtered = filter(options, params);

                                      const { inputValue } = params;
                                      // Suggest the creation of a new value
                                      const isExisting = options.some((option) => inputValue === option.name);
                                      if (inputValue !== '' && !isExisting) {
                                        filtered.push({
                                          inputValue,
                                          name: `Add "${inputValue}"`,
                                        });
                                      }
                                      return filtered;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    id="free-solo-with-text-demo"
                                    options={brandsData}
                                    getOptionLabel={(option) => {
                                      // Value selected with enter, right from the input
                                      if (typeof option === 'string') {
                                        return option;
                                      }
                                      // Add "xxx" option created dynamically
                                      if (option.inputValue) {
                                        return option.inputValue;
                                      }
                                      // Regular option
                                      return option.name;
                                    }}
                                    renderOption={(props, option) => <li {...props}>{option.name}</li>}
                                    sx={{ width: 300 }}
                                    freeSolo
                                    renderInput={(params) => (
                                      <TextField {...params} label="Search / Add Brand" 
                                      variant="outlined"
                                      error={error.errorBrand}
                                      helperText={error.errorBrand ? errorMessage.errorBrand : ''}
                                      />
                                    )}
                                  />
                            </Stack>
                      </span>
                   </div>

                   <div style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    marginBottom: '2rem'
                   }}>
                      <span className="select-label">Select Category
                      </span>
                      <span style={{
                            marginLeft: '35px'
                      }}>
                        <Stack spacing={2} sx={{ 
                            width: '320px',
                            background: '#FFFFFF',
                            borderRadius: '5px',
                          }}>
                              <Autocomplete
                                  style={{
                                    width: '323px',
                                  }}
                                  // value={selectCategories}
                                  onChange= {handleChangeCategory}
                                  disableClearable
                                  onInputChange={handleInputChangeCategory}
                                  filterOptions={(options, params) => {
                                    const filtered = filter(options, params);

                                    const { inputValue } = params;
                                    // Suggest the creation of a new value
                                    const isExisting = options.some((option) => inputValue === option.name);
                                    if (inputValue !== '' && !isExisting) {
                                      filtered.push({
                                        inputValue,
                                        name: `Add "${inputValue}"`,
                                      });
                                    }
                                    return filtered;
                                  }}
                                  selectOnFocus
                                  clearOnBlur
                                  handleHomeEndKeys
                                  id="free-solo-with-text-demo"
                                  options={categoriesData}
                                  getOptionLabel={(option) => {
                                    // Value selected with enter, right from the input
                                    if (typeof option === 'string') {
                                      return option;
                                    }
                                    // Add "xxx" option created dynamically
                                    if (option.inputValue) {
                                      return option.inputValue;
                                    }
                                    // Regular option
                                    return option.name;
                                  }}
                                  renderOption={(props, option) => <li {...props}>{option.name}</li>}
                                  sx={{ width: 300 }}
                                  freeSolo
                                  renderInput={(params) => (
                                    <TextField {...params} label="Search / Add Category" 
                                    variant="outlined"
                                    error={error.errorCategory}
                                    helperText={error.errorCategory ? errorMessage.errorCategory : ''}
                                    />
                                  )}
                                />
                            </Stack>
                      </span>
                   </div>
                </div>
                
                <div style={{display: 'flex', justifyContent:'space-between'}}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    marginBottom: '2rem'

                   }}>
                      <span className="select-label">Select Status
                      </span>
                      <span style={{
                            marginLeft: '35px'
                      }}>
                        <Stack spacing={2} sx={{ 
                            width: '320px',
                            background: '#FFFFFF',
                            borderRadius: '5px',
                          }}>
                             <Autocomplete
                                  style={{
                                    width: '323px',
                                  }}
                                  // value={selectStatus}
                                  onChange= {handleChangeStatus}
                                  disableClearable
                                  onInputChange={handleInputChangeStatus}
                                  filterOptions={(options, params) => {
                                    const filtered = filter(options, params);

                                    const { inputValue } = params;
                                    // Suggest the creation of a new value
                                    const isExisting = options.some((option) => inputValue === option.name);
                                    if (inputValue !== '' && !isExisting) {
                                      filtered.push({
                                        inputValue,
                                        name: `Add "${inputValue}"`,
                                      });
                                    }
                                    return filtered;
                                  }}
                                  selectOnFocus
                                  clearOnBlur
                                  handleHomeEndKeys
                                  id="free-solo-with-text-demo"
                                  options={statusData}
                                  getOptionLabel={(option) => {
                                    // Value selected with enter, right from the input
                                    if (typeof option === 'string') {
                                      return option;
                                    }
                                    // Add "xxx" option created dynamically
                                    if (option.inputValue) {
                                      return option.inputValue;
                                    }
                                    // Regular option
                                    return option.name;
                                  }}
                                  renderOption={(props, option) => <li {...props}>{option.name}</li>}
                                  sx={{ width: 300 }}
                                  freeSolo
                                  renderInput={(params) => (
                                    <TextField {...params} label="Search / Add Status" 
                                    variant="outlined"
                                    error={error.errorStatus}
                                    helperText={error.errorStatus ? errorMessage.errorStatus: ''}
                                    />
                                  )}
                                />
                            </Stack>
                      </span>
                   </div>
                   <div style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    marginBottom: '2rem'
                   }}>
                      <span className="select-label">Select Subcategory
                      </span>
                      <span style={{
                            marginLeft: '35px'
                      }}>
                        <Stack spacing={2} sx={{ 
                            width: '320px',
                            background: '#FFFFFF',
                            borderRadius: '5px',
                          }}>
                              <Autocomplete
                                  style={{
                                    width: '323px',
                                  }}
                                  // value={selectSubCategories}
                                  onChange= {handleChangeSubcategory}
                                  disableClearable
                                  onInputChange={handleInputChangeSubcategory}
                                  filterOptions={(options, params) => {
                                    const filtered = filter(options, params);

                                    const { inputValue } = params;
                                    // Suggest the creation of a new value
                                    const isExisting = options.some((option) => inputValue === option.name);
                                    if (inputValue !== '' && !isExisting) {
                                      filtered.push({
                                        inputValue,
                                        name: `Add "${inputValue}"`,
                                      });
                                    }
                                    return filtered;
                                  }}
                                  selectOnFocus
                                  clearOnBlur
                                  handleHomeEndKeys
                                  id="free-solo-with-text-demo"
                                  options={subcategoriesData}
                                  getOptionLabel={(option) => {
                                    // Value selected with enter, right from the input
                                    if (typeof option === 'string') {
                                      return option;
                                    }
                                    // Add "xxx" option created dynamically
                                    if (option.inputValue) {
                                      return option.inputValue;
                                    }
                                    // Regular option
                                    return option.name;
                                  }}
                                  renderOption={(props, option) => <li {...props}>{option.name}</li>}
                                  sx={{ width: 300 }}
                                  freeSolo
                                  renderInput={(params) => (
                                    <TextField {...params} label="Search / Add Subcategory" 
                                    variant="outlined"
                                    error={error.errorSubcategory}
                                    helperText={error.errorSubcategory ? errorMessage.errorSubcategory : ''}
                                    />
                                  )}
                                />
                            </Stack>
                      </span>
                   </div>
                </div>
                <div style={{display: 'flex', justifyContent:'space-between'}}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    marginBottom: '2rem'

                   }}>
                      <span className="select-label">Select Type
                      </span>
                      <span style={{
                            marginLeft: '35px'
                      }}>
                        <Stack spacing={2} sx={{ 
                            marginLeft:'10px',
                            width: '320px',
                            background: '#FFFFFF',
                            borderRadius: '5px',
                          }}>
                              <Autocomplete
                                  style={{
                                    width: '323px',
                                  }}
                                  // value={selectType}
                                  onChange= {handleChangeType}
                                  disableClearable
                                  onInputChange={handleInputChangeType}
                                  filterOptions={(options, params) => {
                                    const filtered = filter(options, params);

                                    const { inputValue } = params;
                                    // Suggest the creation of a new value
                                    const isExisting = options.some((option) => inputValue === option.name);
                                    if (inputValue !== '' && !isExisting) {
                                      filtered.push({
                                        inputValue,
                                        name: `Add "${inputValue}"`,
                                      });
                                    }
                                    return filtered;
                                  }}
                                  selectOnFocus
                                  clearOnBlur
                                  handleHomeEndKeys
                                  id="free-solo-with-text-demo"
                                  options={typeData}
                                  getOptionLabel={(option) => {
                                    // Value selected with enter, right from the input
                                    if (typeof option === 'string') {
                                      return option;
                                    }
                                    // Add "xxx" option created dynamically
                                    if (option.inputValue) {
                                      return option.inputValue;
                                    }
                                    // Regular option
                                    return option.name;
                                  }}
                                  renderOption={(props, option) => <li {...props}>{option.name}</li>}
                                  sx={{ width: 300 }}
                                  freeSolo
                                  renderInput={(params) => (
                                    <TextField {...params} label="Search / Add Type" 
                                    variant="outlined"
                                    error={error.errorType}
                                    helperText={error.errorType ? errorMessage.errorType : ''}
                                    />
                                  )}
                                />
                            </Stack>
                      </span>
                   </div>
                   <div style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    marginBottom: '2rem'

                   }}>
                      <span className="select-label">Select Market
                      </span>
                      <span style={{
                            marginLeft: '35px'
                      }}>
                        <Stack spacing={2} sx={{ 
                            width: '320px',
                            background: '#FFFFFF',
                            borderRadius: '5px',
                          }}>
                              <Autocomplete
                                style={{
                                  width: '323px',
                                }}
                                // value={selectMarket}
                                onChange= {handleChangeMarket}
                                disableClearable
                                onInputChange={handleInputChangeMarket}
                                filterOptions={(options, params) => {
                                  const filtered = filter(options, params);

                                  const { inputValue } = params;
                                  // Suggest the creation of a new value
                                  const isExisting = options.some((option) => inputValue === option.name);
                                  if (inputValue !== '' && !isExisting) {
                                    filtered.push({
                                      inputValue,
                                      name: `Add "${inputValue}"`,
                                    });
                                  }
                                  return filtered;
                                }}
                                selectOnFocus
                                clearOnBlur
                                handleHomeEndKeys
                                id="free-solo-with-text-demo"
                                options={marketsData}
                                getOptionLabel={(option) => {
                                  // Value selected with enter, right from the input
                                  if (typeof option === 'string') {
                                    return option;
                                  }
                                  // Add "xxx" option created dynamically
                                  if (option.inputValue) {
                                    return option.inputValue;
                                  }
                                  // Regular option
                                  return option.name;
                                }}
                                renderOption={(props, option) => <li {...props}>{option.name}</li>}
                                sx={{ width: 300 }}
                                freeSolo
                                renderInput={(params) => (
                                  <TextField {...params} label="Search / Add Market" 
                                  variant="outlined"
                                  error={error.errorMarket}
                                  helperText={error.errorMarket ? errorMessage.errorMarket: ''}
                                  />
                                )}
                              />
                            </Stack>
                      </span>
                   </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
          <div  className="submit-button">
            <button type="submit" className="button"  onClick={addNewProduct} 
              >
                {isLoading ? 'Adding Data ..........' : 'Submit'}
              </button>
          </div>
        </Modal.Footer>
        </Modal>
         
    )
}
export default AlertAddProductModal;