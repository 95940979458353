import React from 'react';
import { Button } from 'react-bootstrap';
import '../../styles/Signin.css'
import ResoluteAiLogo from '../../assets/logo/ResoluteAiLogo.png';
import LoginWithMicrosoft from './LoginWithMicrosoft';
import LoginWithGoogle from './LoginWithGoogle';
import { useNavigate } from 'react-router-dom';
import { auth } from '../Firebase/Firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useState } from 'react';
import { Form, Row, Col, InputGroup } from 'react-bootstrap';

import {toast} from 'react-toastify';
const SigninFormComponent = () => {

    const navigate = useNavigate();
    const [data, setData] = useState({});

    const handleInput = (event) => {
        let newInput = {
            [event.target.name]: event.target.value,
        };
        setData({ ...data, ...newInput });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log(data)
       await signInWithEmailAndPassword(auth, data.email, data.password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                sessionStorage.setItem("auth", JSON.stringify(user))
                sessionStorage.setItem("email", data.email)
                localStorage.setItem('Display Name', '');
                 navigate("/dashboard");
                 toast.success("Signin Successfully");

                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                alert(error.message)
                const errorMessage = error.message;
            });
    };

    return (
        <React.Fragment>
            {/* <Row>
                <Form.Group className="mb-4" controlId="formBasicEmail">
                    <input type='email' className='form-control' placeholder='Enter Email Id' id='emailId' name='email' onChange={handleInput}/>
                </Form.Group>
                <Form.Group className="mb-4" controlId="formBasicEmail">
                    <InputGroup>
                        <input
                           type='password'
                            className="form-control" 
                            name="password" 
                            placeholder="Enter Password" 
                            id='passwordId'
                            onChange={handleInput}
                        />
                    </InputGroup>
                </Form.Group>
              </Row> */}
            {/* <Row>
                <Col xs={12}>
                <Button type='button'  className='login-button' 
                    style={{background: '#F33823'}}
                     onClick={handleSubmit}>
                    Login</Button>
                </Col>
            </Row> */}
            <Row>
                <Col xs={12}>
                    <LoginWithGoogle />
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <LoginWithMicrosoft/>
                </Col>
            </Row>

            <Row>
                <Col xs={12} style={{ textAlign: 'center' }} >
                    <p style={{ textAlign: 'center', marginTop: '-10px' }}>Product From</p>
                </Col>
            </Row>
            <Row>
                <Col xs={12} style={{ textAlign: 'center' }}>
                    <img src={ResoluteAiLogo} alt="Resolute AI Logo" width={138} />
                </Col>
            </Row>

        </React.Fragment>
    )
}

export default SigninFormComponent