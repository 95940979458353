import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isAddTemplatesModalOpen: false,
    isLogoutModalOpen: false,
    isImagesModalOpen: false,
    isViewTableDataModalOpen: false,
    isEditTableDataModalOpen: false,
    isDeleteTableDataModalOpen: false,
    isAddProductModalOpen: false,
    isProductImageModalOpen: false,
    isProductViewModalOpen: false,
    isProductEditModalOpen: false,
    isProductDeleteModalOpen: false,
    isAddBrandModalOpen: false,
    isViewBrandModalOpen: false,
    isEditBrandModalOpen: false,
    isDeleteBrandModalOpen: false,
    isAddCategoriesModalOpen: false,
    isViewCategoriesModalOpen: false,
    isEditCategoriesModalOpen: false,
    isDeleteCategoriesModalOpen: false,
    isAddMarketModalOpen: false,
    isViewMarketModalOpen: false,
    isEditMarketModalOpen: false,
    isDeleteMarketModalOpen: false,
    isAddStatusModalOpen: false,
    isViewStatusModalOpen: false,
    isEditStatusModalOpen: false,
    isDeleteStatusModalOpen: false,
    isAddSubCategoriesModalOpen: false,
    isViewSubCategoriesModalOpen: false,
    isEditSubCategoriesModalOpen: false,
    isDeleteSubCategoriesModalOpen: false,
    isAddTypeModalOpen: false,
    isViewTypeModalOpen: false,
    isEditTypeModalOpen: false,
    isDeleteTypeModalOpen: false,
    
}
const slice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        toggleAddTemplatesModalHandler(state, action){
            state.isAddTemplatesModalOpen = !state.isAddTemplatesModalOpen;
        },
        toggleLogoutModalHandler(state, action){
            state.isLogoutModalOpen = !state.isLogoutModalOpen;
        },
        toggleImagesModalHandler(state,action){
            state.isImagesModalOpen = !state.isImagesModalOpen;
        },
        toggleViewTableDataModalHandler(state, action){
              state.isViewTableDataModalOpen = !state.isViewTableDataModalOpen;
        },
        toggleEditTableDataModalHandler(state, action){
              state.isEditTableDataModalOpen = !state.isEditTableDataModalOpen;
        },
        toggleDeleteTableDataModalHandler(state, action){
              state.isDeleteTableDataModalOpen = !state.isDeleteTableDataModalOpen;
        },
        toggleAddProductModalHandler(state, action){
            state.isAddProductModalOpen = !state.isAddProductModalOpen
        },
        toggleProductViewModalHandler(state, action){
            state.isProductViewModalOpen = !state.isProductViewModalOpen
        },
        toggleProductEditModalHandler(state, action){
            state.isProductEditModalOpen = !state.isProductEditModalOpen
        },
        toggleProductDeleteModalHandler(state, action){
            state.isProductDeleteModalOpen = !state.isProductDeleteModalOpen
        },
        toggleProductImageModalHandler(state, action){
            state.isProductImageModalOpen = !state.isProductImageModalOpen
        },
        toggleAddBrandModalHandler(state, action){
            state.isAddBrandModalOpen = !state.isAddBrandModalOpen
        },
        toggleViewBrandModalHandler(state, action){
            state.isViewBrandModalOpen = !state.isViewBrandModalOpen
        },
        toggleEditBrandModalHandler(state, action){
            state.isEditBrandModalOpen = !state.isEditBrandModalOpen
        },
        toggleDeleteBrandModalHandler(state, action){
            state.isDeleteBrandModalOpen = !state.isDeleteBrandModalOpen
        },
        toggleAddCategoriesModalHandler(state, action){
            state.isAddCategoriesModalOpen = !state.isAddCategoriesModalOpen
        },
        toggleViewCategoriesModalHandler(state, action){
            state.isViewCategoriesModalOpen = !state.isViewCategoriesModalOpen
        },
        toggleEditCategoriesModalHandler(state, action){
            state.isEditCategoriesModalOpen = !state.isEditCategoriesModalOpen
        },
        toggleDeleteCategoriesModalHandler(state, action){
            state.isDeleteCategoriesModalOpen = !state.isDeleteCategoriesModalOpen
        },
        toggleAddMarketModalHandler(state, action){
            state.isAddMarketModalOpen = !state.isAddMarketModalOpen
        },
        toggleViewMarketModalHandler(state, action){
            state.isViewMarketModalOpen = !state.isViewMarketModalOpen
        },
        toggleEditMarketModalHandler(state, action){
            state.isEditMarketModalOpen = !state.isEditMarketModalOpen
        },
        toggleDeleteMarketModalHandler(state, action){
            state.isDeleteMarketModalOpen = !state.isDeleteMarketModalOpen
        },
        toggleAddSubCategoriesModalHandler(state, action){
            state.isAddSubCategoriesModalOpen = !state.isAddSubCategoriesModalOpen
        },
        toggleViewSubCategoriesModalHandler(state, action){
            state.isViewSubCategoriesModalOpen = !state.isViewSubCategoriesModalOpen
        },
        toggleEditSubCategoriesModalHandler(state, action){
            state.isEditSubCategoriesModalOpen = !state.isEditSubCategoriesModalOpen
        },
        toggleDeleteSubCategoriesModalHandler(state, action){
            state.isDeleteSubCategoriesModalOpen = !state.isDeleteSubCategoriesModalOpen
        },
        toggleAddStatusModalHandler(state, action){
            state.isAddStatusModalOpen = !state.isAddStatusModalOpen
        },
        toggleViewStatusModalHandler(state, action){
            state.isViewStatusModalOpen = !state.isViewStatusModalOpen
        },
        toggleEditStatusModalHandler(state, action){
            state.isEditStatusModalOpen = !state.isEditStatusModalOpen
        },
        toggleDeleteStatusModalHandler(state, action){
            state.isDeleteStatusModalOpen = !state.isDeleteStatusModalOpen
        },
        toggleAddTypeModalHandler(state, action){
            state.isAddTypeModalOpen = !state.isAddTypeModalOpen
        },
        toggleViewTypeModalHandler(state, action){
            state.isViewTypeModalOpen = !state.isViewTypeModalOpen
        },
        toggleEditTypeModalHandler(state, action){
            state.isEditTypeModalOpen = !state.isEditTypeModalOpen
        },
        toggleDeleteTypeModalHandler(state, action){
            state.isDeleteTypeModalOpen = !state.isDeleteTypeModalOpen
        },
    }
});
export const modalActions = slice.actions;
export default slice.reducer;