import React, { useEffect, useState } from "react";
import {InputGroup} from "react-bootstrap";
import {FiSearch} from "react-icons/fi";
import { useDispatch } from "react-redux";
import { modalActions } from "../store/modal";
import {doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../Firebase/Firebase";
import '../../styles/Signin.css'
import CategoriesTable from "./CategoriesTable";
import AlertAddCategoriesModal from "../Modals/AddCategoriesModal";

const Categories = ()=>{
    const dispatch = useDispatch();
    const [categories, setCategories]  = useState([]);
    const [categoriesId,setCategoriesId] = useState("");
    const [dataApi, setDataApi] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const getCategoriesDocument = async ()=>{

            try{
              const categoriesRef = doc(db, 'masters', 'categories');
                const categoriesDoc =  await getDoc(categoriesRef);
                if(categoriesDoc.exists()){
                  const categoriesData = categoriesDoc.data();
                  const {CATEGORIES} = categoriesData;
                  
                  setCategories(CATEGORIES);
                  setCategoriesId(categoriesDoc.id)
                  setDataApi(CATEGORIES)
                  // console.log('get categories data sucessfully');
                }
                else{
                  await setDoc(categoriesRef, {
                        CATEGORIES: []
                  })
                  // console.log('document add sucessfully');
                  setCategoriesId(categoriesDoc.id);
                }
            }
            catch(error){
              console.log(error);
            }
    }
    
    useEffect(()=>{
        getCategoriesDocument();
    },[])

    const handleSearch = (e)=>{
          if(e.target.value === ''){
            setCategories(dataApi);
          }
          else{

              const lowerCaseSearchTerm = searchTerm.trim().toLowerCase();
              
              const filterData = dataApi.filter((item)=> {
                const date = new Date(item.updatedAt.seconds * 1000 + item.updatedAt.nanoseconds / 1000000);
                // console.log(date);
                const formattedDate = date.toLocaleDateString().split('/').join('-');
                const formattedTime = date.toLocaleTimeString('en-US', { hour12: false });

                      let categoriesName = item.name || '';
                      let createdCategoriesAtDate = formattedDate || ''
                      let createdCategoriesAtTime = formattedTime || ''
                      let uploaded_by = item.uploadedBy || ''
                  
                  return (
                    categoriesName.toLowerCase().includes(lowerCaseSearchTerm) ||
                    createdCategoriesAtDate.toLowerCase().includes(lowerCaseSearchTerm) ||
                    createdCategoriesAtTime.toLowerCase().includes(lowerCaseSearchTerm) ||
                    uploaded_by.toLowerCase().includes(lowerCaseSearchTerm)
                  )
                    
              });

                setCategories(filterData);
          }
          setSearchTerm(e.target.value);
    }

    return (
        <React.Fragment>
            <div
            style={{
               position:'fixed',
               top:110,
               left:240,
               width:'82%',
               height:'100%',
               overflowY: 'scroll'
            }} 
            
            >
                  
                  <div className="manage-templates-header"  style={{display: 'flex',justifyContent: 'space-between', marginBottom:'2rem', alignItems:'center'}}>
                    <span className="manage-templates-text">Manage Category</span>
                    <span className="add-templates-header" onClick={()=> dispatch(modalActions.toggleAddCategoriesModalHandler())} >Add Category <span className="plus">+</span></span>
                  </div>
              
                  <InputGroup>
                    <input type="search" id="input-placeholder" autoComplete="off" placeholder="Search by category name, date, time, uploaded by" value={searchTerm} 
                    onChange={(e)=>handleSearch(e)} 
                    className="form-control" style={{padding: '10px',
                    background: '#FFFFFF',
                    boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.2)'}}/>
                    <FiSearch
                     style={{
                          position:'absolute',
                          top:'50%',
                          left:'10px',
                          transform: 'translateY(-50%)',
                          pointerEvents: 'cursor'
                     }}
                    />
                  </InputGroup>
              
              <div style={{marginTop:'2rem'}}>
                  <CategoriesTable categories={categories} setCategories={setCategories} categoriesId={categoriesId} setDataApi={setDataApi} dataApi={dataApi} setSearchTerm={setSearchTerm}/>
              </div>
            </div>
            <AlertAddCategoriesModal categories={categories} setCategories={setCategories} categoriesId={categoriesId} setDataApi={setDataApi}/>
        </React.Fragment>
    )
}
export  default Categories;
