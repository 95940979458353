import {Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from '../store/modal';

const ImagesModal = (props)=>{
    const dispatch = useDispatch();
    const isImagesModalOpen  = useSelector(state => state.modal.isImagesModalOpen);
    const {rowImages} = props;
    return(
        <Modal 
            size="sm"
            centered
            show={isImagesModalOpen} 
            onHide={() => dispatch(modalActions.toggleImagesModalHandler())}
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body> 
            <img src={rowImages} alt="MMD Image" style={{width
                        :'270px', height:'300px',marginRight:'50px'}} />
            </Modal.Body>
        </Modal>
         
    )
}
export default ImagesModal;