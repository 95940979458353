import React, { useEffect, useState } from "react"
import Card1 from '../Dashboard/Card1';
import Card2 from '../Dashboard/Card2';
import Card3 from '../Dashboard/Card3';
import Card4 from '../Dashboard/Card4';
import '../../../styles/Signin.css';
import {BubbleChart, PieChart} from "./Graph";
import { db } from "../../Firebase/Firebase";
import { getDocs, collection, getDoc, doc } from "firebase/firestore";
// import { templateActions } from "../../store/templatesData";
// import { useDispatch } from "react-redux";
const Dashboard = () => {

  const[templateData, setTemplateData] = useState([]);
  const[productData, setProductData] = useState([]);

  useEffect(()=>{
      const getTemplateData = async ()=>{
             try{
              const querySnapshot =  await  getDocs(collection(db, "templates"));
              const data = [];
              querySnapshot.forEach(doc => {
                  data.push({...doc.data(), id: doc.id});
                   
              })
              setTemplateData(data);
              // dispatch(templateActions.setTemplatesData(data))
             }
             catch(error){
              console.log(error);
             }
      }
      getTemplateData();
      
  },[])


  useEffect(()=>{
    const getProductData = async ()=>{

      try{
       const querySnapshot =  await  getDocs(collection(db, "products"));
       const data = [];
        
       querySnapshot.forEach(doc => {
           data.push({...doc.data(), id: doc.id});
            
       })
       setProductData(data);

      }
      catch(error){
       console.log(error);
      }
}
  getProductData();
  },[])
    return (
        <React.Fragment>
            <div
            style={{
              position:'fixed',
              left:'240px',
              top:'110px',
              width:'82%',
              height:'100%',
            }} 
            
            >
                <span
                style={{
                  fontStyle: 'normal',
                  fontWeight: 700,
                  lineHeight: '21px',
                  fontSize: '16px'
                }}
                >Dashboard</span>
                <div 
                  style={{ display: "flex", justifyContent: "space-between",width:'100%', marginTop:'20px'}}>
                    <Card1 templateData={templateData} setTemplateData={setTemplateData}/>
                    <Card2 productData={productData} setProductData={setProductData}/>
                    <Card3 productData={productData} setProductData={setProductData}/>
                    <Card4 productData={productData} setProductData={setProductData}/>
                </div>
                <div style={{display:'flex', justifyContent:'space-between', marginTop:'50px'}}>
                  <div style={{
                    display:'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width:'30%', height:'30%'}}>
                    <PieChart templateData={templateData} productData={productData}/>
                    <div className="manage-templates-text">Product Comparison</div>
                  </div>
                  <div style={{width:'60%'}}>
                    <BubbleChart/>
                  </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Dashboard;
 