import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '../store/modal';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import {auth} from '../Firebase/Firebase';
import { toast } from 'react-toastify';


const  LogoutModal = ()=> {
   const isLogoutModalOpen = useSelector(state => state.modal.isLogoutModalOpen);
   const dispatch = useDispatch();
   const navigate = useNavigate();
 
    const handleSignOut = async ()=>{
         await signOut(auth).then(()=>{
              toast.success("Logout successfully")
              navigate('/')
              sessionStorage.setItem("email", "");
              localStorage.setItem("Email Id", "");
              localStorage.setItem("Display Name", "")
               
           }).catch((error)=>{
            console.log(error);
           })
          
           const handleLogoutAfterNavigate = ()=>{
                dispatch(modalActions.toggleLogoutModalHandler())
           }
           handleLogoutAfterNavigate();
    }

  return (
    <>
      <Modal
        centered
        show={isLogoutModalOpen}
        onHide={()=> dispatch(modalActions.toggleLogoutModalHandler())}
         
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{textAlign:'center'}}>
           Are you sure want to logout ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSignOut}>Yes</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default  LogoutModal;