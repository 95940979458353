import {  Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "../store/modal";
import { updateDoc, doc, getDocs, collection, Timestamp, query, where } from "firebase/firestore";
import { db } from "../Firebase/Firebase";
import { toast } from "react-toastify";
import { updateTemplatesCollection } from "../HomePage/ManageTemplates/UpdateTemplates";
const EditProductModalOpen = (props)=>{
    const isProductEditModalOpen = useSelector((state)=> state.modal.isProductEditModalOpen);
    const dispatch = useDispatch();
    const {products, setProducts, selectedEditRow, setSelectedEditRow, setDataApi, dataApi, setSearchTerm} = props;

    const handleOnchangeProductName = (e)=>{
          setSelectedEditRow({...selectedEditRow, name: e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)});
    }
  const updateTemplateDocument = async (id)=>{
        try{
          await updateDoc(doc(db, "templates", id ),{
            product: selectedEditRow.name.charAt(0).toUpperCase() + selectedEditRow.name.slice(1),
            updatedAt: Timestamp.fromDate(new Date())
          });
          updateTemplatesCollection();
         }
        catch(error){
          console.log(error);
        }
  }
  
     const fetchTemplateDocument = async (id)=>{
      const q = query(collection(db, "templates"), where('productId', '==', id));
      let querySnapshot =  await getDocs(q);

       if(querySnapshot.size > 0){
          querySnapshot.forEach(doc=>{
               updateTemplateDocument(doc.id)
          })
       }
       else{
        console.log("template doesn't exists");
       }
     }
    const handleEditDoc =  async()=>{
             
            if(dataApi.some(item => item.name === selectedEditRow.name)){
              alert('Please enter unique  product name');
              return;
            }
            else if(selectedEditRow.name === ''){
              alert('Please enter product name ');
              return;
            }
            else{
              try{
                await updateDoc(doc(db, "products", selectedEditRow.id),{
                name: selectedEditRow.name.charAt(0).toUpperCase() + selectedEditRow.name.slice(1),
                updatedAt: Timestamp.fromDate(new Date())
                });
                
                dispatch(modalActions.toggleProductEditModalHandler());
                handleUpdatedProducts();
                fetchTemplateDocument(selectedEditRow.id);
                setSearchTerm('');
                toast.success("Edit Product Successfully")
              }
             catch(error){
              console.log(error);
             }
            }
           
    }
    const handleUpdatedProducts = async()=>{
      try{
          const querySnapshot = await getDocs(collection(db, "products"));
          const newData = [];
          querySnapshot.forEach(doc => {
            newData.push({...doc.data(), id:doc.id});
             
            })
          setProducts(newData);
          setDataApi(newData);
      }
      catch(error){
       console.log(error);
      }
   }
   const handleOnHide = ()=>{
         dispatch(modalActions.toggleProductEditModalHandler())
   }

    return (
        <Modal 
        size="lg"
        centered
        show={isProductEditModalOpen} 
        onHide={handleOnHide}
    >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{padding: '2rem'}}>
            <div>
            <p className="add-template">Edit Product </p>
            <label className="template-label">Product Name
                <input type="text" className="template-name" 
                name="name"
                value={selectedEditRow.name} 
                onChange={handleOnchangeProductName}
                autoComplete="off"
                />
            </label>
       </div>
        </Modal.Body>
        <Modal.Footer>
      <div  className="submit-button">
        <button type="submit" className="button" 
        onClick={handleEditDoc}
        >Submit</button>
      </div>
    </Modal.Footer>
    </Modal>
    )
}
export default EditProductModalOpen