import React, { useEffect, useState } from "react";
import {InputGroup} from "react-bootstrap";
import {FiSearch} from "react-icons/fi";
import { useDispatch } from "react-redux";
import { modalActions } from "../store/modal";
import {collection, doc, getDoc, setDoc,getDocs } from "firebase/firestore";

import { db } from "../Firebase/Firebase";
import '../../styles/Signin.css'
import AlertAddStatusModal from "../Modals/AddStatusModal";
import StatusTable from "./StatusTable";

const Status = ()=>{
    const dispatch = useDispatch();
    const [status, setStatus]  = useState([]);
    const [statusId,setStatusId] = useState("");
    const [dataApi, setDataApi] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const getStatusDocument = async ()=>{
          try{
            const statusRef = doc(db, 'masters', 'status');
              const statusDoc =  await getDoc(statusRef);
              if(statusDoc.exists()){
                const statusData = statusDoc.data();
                const {STATUS} = statusData;
                
                setStatus(STATUS);
                setStatusId(statusDoc.id)
                setDataApi(STATUS)
                // console.log('get status data sucessfully');
              }
              else{
                await setDoc(statusRef, {
                      STATUS: []
                })
                // console.log('document add sucessfully');
                setStatusId(statusDoc.id);
              }
          }
          catch(error){
            console.log(error);
          }
       
    }
    
    useEffect(()=>{
        getStatusDocument();
    },[])

    const handleSearch = (e)=>{
          if(e.target.value === ''){
            setStatus(dataApi);
          }
          else{

              const lowerCaseSearchTerm = searchTerm.trim().toLowerCase();
              
              const filterData = dataApi.filter((item)=> {
                const date = new Date(item.updatedAt.seconds * 1000 + item.updatedAt.nanoseconds / 1000000);
                // console.log(date);
                const formattedDate = date.toLocaleDateString().split('/').join('-');
                const formattedTime = date.toLocaleTimeString('en-US', { hour12: false });

                      let brandName = item.name || '';
                      let createdBrandAtDate = formattedDate || ''
                      let createdBrandAtTime = formattedTime || ''
                      let uploaded_by = item.uploadedBy || ''
                  
                  return (
                    brandName.toLowerCase().includes(lowerCaseSearchTerm) ||
                    createdBrandAtDate.toLowerCase().includes(lowerCaseSearchTerm) ||
                    createdBrandAtTime.toLowerCase().includes(lowerCaseSearchTerm) ||
                    uploaded_by.toLowerCase().includes(lowerCaseSearchTerm)
                  )
                    
              });

                setStatus(filterData);
          }
          setSearchTerm(e.target.value);
    }

    return (
        <React.Fragment>
            <div
            style={{
               position:'fixed',
               top:110,
               left:240,
               width:'82%',
               height:'100%',
               overflowY: 'scroll'
            }} 
            
            >
                  
                  <div className="manage-templates-header"  style={{display: 'flex',justifyContent: 'space-between', marginBottom:'2rem', alignItems:'center'}}>
                    <span className="manage-templates-text">Manage Status</span>
                    <span className="add-templates-header" onClick={()=> dispatch(modalActions.toggleAddStatusModalHandler())} >Add Status <span className="plus">+</span></span>
                  </div>
              
                  <InputGroup>
                    <input type="search" id="input-placeholder" autoComplete="off" placeholder="Search by status name, date, time, uploaded by" value={searchTerm} 
                    onChange={(e)=>handleSearch(e)} 
                    className="form-control" style={{padding: '10px',
                    background: '#FFFFFF',
                    boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.2)'}}/>
                    <FiSearch
                     style={{
                          position:'absolute',
                          top:'50%',
                          left:'10px',
                          transform: 'translateY(-50%)',
                          pointerEvents: 'cursor'
                     }}
                    />
                  </InputGroup>
              
              <div style={{marginTop:'2rem'}}>
                  <StatusTable status={status} setStatus={setStatus} statusId={statusId} setDataApi={setDataApi} dataApi={dataApi} setSearchTerm={setSearchTerm}/>
              </div>
            </div>
            <AlertAddStatusModal status={status} setStatus={setStatus} statusId={statusId} setDataApi={setDataApi}/>
        </React.Fragment>
    )
}
export  default Status;
