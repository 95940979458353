import React from 'react';
import { IoIosArrowDown, IoIosArrowBack } from "react-icons/io";
import { FiColumns } from "react-icons/fi";
  
export const SidebarData = [
  {
    title: 'Master',
    path: '/masters',
    icon: <FiColumns/>,
    iconClosed: <IoIosArrowBack/>,
    iconOpened: <IoIosArrowDown/>,

    subNav: [
      {
        title: '- Brand',
        path: '/brands',
        icon: ''
      },
      {
        title: '- Category',
        path: '/categories',
        icon: ''
      },
      {
        title: '- Market',
        path: '/market',
        icon: ''
      },
      {
        title: '- Status',
        path: '/status',
        icon: ''
      },
      {
        title: '- Subcategory',
        path: '/sub-categories',
        icon: ''
      },
      {
        title: '- Type' ,
        path: '/type',
        icon: ''
      },

    ]
  },
];
