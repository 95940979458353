import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "../store/modal";
import { db } from "../Firebase/Firebase";
import { deleteDoc,doc, collection, getDocs, where, query, updateDoc, Timestamp } from "firebase/firestore";
import { toast } from "react-toastify";
import {ref, listAll, deleteObject } from 'firebase/storage';
import { storage } from "../Firebase/Firebase";
import { updateTemplatesCollection } from "../HomePage/ManageTemplates/UpdateTemplates";

const DeleteTableDataModalOpen = (props)=>{
    const isDeleteTableDataModalOpen = useSelector((state)=> state.modal.isDeleteTableDataModalOpen);
    const dispatch = useDispatch();
    const {selectedRow, setSelectedRow, setTemplates, setDataApi, dataApi, setSearchTerm, setSelectImagesRow, selectImagesRow} = props;

    const handleDeleteDoc = async()=>{
      const q = query(collection(db, "products"), where('name', '==', selectedRow.product));
      let querySnapshot =  await getDocs(q);
      const selectedProductId = querySnapshot.docs[0].id;
      const {product_version} = querySnapshot.docs[0].data();
      const filterData = product_version.filter(item => item !== selectedRow.name);

              try{
                const newData = dataApi.filter(item => item.id !== selectedRow.id);
                // console.log(newData);
                await deleteDoc(doc(db, "templates", selectedRow.id));
                handleDeleteImage();
                updateProductDocument(filterData, selectedProductId);
                setTemplates(newData);
                setDataApi(newData);
                dispatch(modalActions.toggleDeleteTableDataModalHandler())
                setSelectedRow(null);
                setSelectImagesRow([]);
                setSearchTerm('');
                toast.success("Delete Successfully");
                updateTemplatesCollection();
              }
              catch(error){
                console.log(error);
              }
    }

    const deleteImageByUrl = async (imageUrl) => {
      try {
        // Extract the image path from the URL
        const imagePath = getImagePathFromUrl(imageUrl);
        // console.log(imagePath, 'image path');
        // Get a reference to the image file
        const imageRef = ref(storage, imagePath)
        // console.log(imageRef, 'image reference');
        // console.log(imageRef.fullPath, 'folder  path');
        const folderPath = imageRef.fullPath;
        const folderRef = ref(storage, folderPath);
        // Get a list of files within the folder
        const fileList = await listAll(folderRef);

        // Delete each file within the folder
        const deleteFilePromises = fileList.items.map((file) =>
          deleteObject(ref(storage, `${folderPath}/${file.name}`))
        );
        await Promise.all(deleteFilePromises);

        // Delete the empty folder
        await deleteObject(folderRef);
        // Delete the image file
        // await imageRef.delete();
    
        console.log('Image deleted successfully.');
      } catch (error) {
        console.error('Error deleting image:', error);
      }
    };
    function getImagePathFromUrl(imageUrl) {
      // console.log(imageUrl, 'image url');
      // Split the URL by the bucket URL
      const parts = imageUrl.split('https://storage.googleapis.com/');
      // console.log(parts, 'parts');
      // Extract the path after the bucket URL
      const path = parts[0];
      // console.log(path, 'path');
      // Return the image path
      return path;
    }

    const handleDeleteImage = ()=>{
      selectImagesRow.forEach(item=>{
        deleteImageByUrl(item.url);
      })
          
          
     }
     
    const updateProductDocument = async(filterData, selectedProductId)=>{
      try{
         await updateDoc(doc(db, "products", selectedProductId ),{
              product_version: filterData,
              updatedAt: Timestamp.fromDate(new Date())
         });
      }
      catch(error){
         console.log(error);
      }
}
    return (
        <>
             <Modal
             centered
             show={isDeleteTableDataModalOpen}
             onHide={()=> dispatch(modalActions.toggleDeleteTableDataModalHandler())}
           >
             <Modal.Header closeButton></Modal.Header>
             <Modal.Body style={{textAlign:'center'}}>
                Are you sure want to delete ?
             </Modal.Body>
             <Modal.Footer>
               <Button variant="primary" 
               onClick={handleDeleteDoc}
               >Confirm</Button>
               <Button variant="primary" 
               onClick={()=> dispatch(modalActions.toggleDeleteTableDataModalHandler())}
               >Cancel</Button>
             </Modal.Footer>
           </Modal>
          
        </>
    )
}
export default DeleteTableDataModalOpen;