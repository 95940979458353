import {setDoc,doc, getDoc, updateDoc} from "firebase/firestore";
import { db } from "../../Firebase/Firebase";
import { Timestamp } from "firebase/firestore";

export const updateTemplatesCollection = async ()=>{
    try{
        const metaRef = doc(db, 'meta', 'status');
          const metaDoc =  await getDoc(metaRef);
          if(metaDoc.exists()){
            await updateDoc(metaRef, {
                training_required: true,
                updatedAt: Timestamp.fromDate(new Date()),
            })
          }
          else{
            await setDoc(metaRef, {
                 training_required: true,
                 updatedAt: Timestamp.fromDate(new Date()),
            })
          }
      }
      catch(error){
        console.log(error);
      }    
}