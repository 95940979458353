import React from "react";
// import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import '../../styles/Signin.css'

const LoginWithMicrosoft = ()=>{
    // const navigate = useNavigate();
      const handleWithMicrosoft = ()=>{
        // navigate('/dashboard');
      }
    return(
        <> 
          <Button type='button'  className='login-button'
           style={{background: ' #F33823'}} 
           onClick={handleWithMicrosoft}>
          <img src="https://pbs.twimg.com/profile_images/1640484669371355136/IE6k-Gsc_400x400.png" alt='logo' width={25} style={{
             position:'fixed',
             left:'67.5%',
              
          }}/>
          Sign in with Microsoft</Button>
        </>
    )
}
export default LoginWithMicrosoft;