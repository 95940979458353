import * as React from 'react';
import {Chart, Title, Tooltip, ArcElement, Legend, LinearScale, PointElement } from 'chart.js';
import { Pie, Bubble } from 'react-chartjs-2';

Chart.register(
  Title, Tooltip, ArcElement, Legend, LinearScale, PointElement
)

const BubbleChart = ()=>{
  const data = {
    datasets: [{
      label: 'First Dataset',
      data: [{
        x: 10,
        y: 15,
        r: 8
      },
      //  {
      //   x: 40,
      //   y: 10,
      //   r: 10
      // }
    ],
      backgroundColor: 'rgb(255, 99, 132)'
    },
    {
      label: 'Second Dataset',
      data: [{
        x: 8,
        y: 7,
        r: 8
      },
      //  {
      //   x: 50,
      //   y: 20,
      //   r: 10
      // }
    ],
      backgroundColor: 'rgb(100, 00, 00)'
    },
    
    ]
  };
  return <Bubble data={data}/>
}
 
const PieChart = (props)=>{
        const  {productData} = props;

        let countMmd = 0;
        let countNonMmd = 0;
        let others = 0;
        productData.forEach((item)=>{
            if(item.tag === 'others'){
              // console.log(item);
                others++;
            }
            if(item.mmd){
                countMmd++
            }
            else if(!item.mmd){
                    countNonMmd++;
            }
        })

        const data = {
          datasets: [{
            data: [countMmd, countNonMmd, others],
            backgroundColor: [
              'rgb(255, 99, 132)',
              'rgb(54, 162, 235)',
              'rgb(255, 205, 86)'
            ],
          }],
          labels: [
            'MMD',
            'NON-MMD',
            'OTHERS'
          ]
      };
      return <Pie data={data}/>
}
 
export  { PieChart, BubbleChart};
   
