import {Modal} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "../store/modal";
import TextField from '@mui/material/TextField';

const ViewProductModalOpen = (props)=>{
    const isProductViewModalOpen = useSelector((state)=> state.modal.isProductViewModalOpen);
    const dispatch = useDispatch();
    const {selectedViewRow} = props;
    // console.log(selectedViewRow);
    const {name, template_count, brand, category, subcategory, market, type, tag, product_competitor, status} = selectedViewRow;
     
    return (
        <Modal 
        size="xl"
        centered
        show={isProductViewModalOpen} 
        onHide={() => dispatch(modalActions.toggleProductViewModalHandler())}
    >
        <Modal.Header closeButton ></Modal.Header>
        <Modal.Body style={{padding: '2rem'}}>
            <p className="add-template"> Product Details</p>
            <div style={{display: 'flex', justifyContent:'space-between'}}>
                  <span className="view-select-label">Product Name </span>
                  
                  <span style={{marginLeft:'40px'}}>
                      <TextField
                      style={{
                        width:'323px'
                      }}
                      value={name}
                    />
                  </span>
                 <div style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    marginBottom: '2rem'
                   }}>
                      <span className="view-select-label">  Competitor / Others
                          </span>
                          <span style={{
                                marginLeft: '35px'
                          }}>
                         <TextField
                      style={{
                        width:'323px'
                      }}
                      value={tag}
                    />
                             
                      </span>
                   </div>
                </div>
                <div style={{
                  display:'flex',
                  justifyContent:'flex-start',
                }}>
                    <span className="view-select-label"> Competitor Product
                      </span>
                      <span style={{
                            marginLeft: '35px'
                      }}>
                          <TextField
                      style={{
                        width:'323px'
                      }}
                      value={product_competitor}
                    />
                      </span>
                      <div style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    marginBottom: '2rem',
                    marginLeft: '40px'
                   }}>
                      <span className="view-select-label"> Template Count
                          </span>
                          <span style={{
                          }}>
                         <TextField
                      style={{
                        width:'323px',
                        marginLeft: '67px'
                      }}
                      value={template_count}
                    />
                             
                      </span>
                   </div>
                </div>
                <div style={{display: 'flex', justifyContent:'space-between'}}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    marginBottom: '2rem'

                   }}>
                      <span className="view-select-label"> Brand Name
                      </span>
                      <span style={{
                            marginLeft: '90px'
                      }}>
                          <TextField
                      style={{
                        width:'323px'
                      }}
                      value={brand}
                    />
                      </span>
                   </div>

                   <div style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    marginBottom: '2rem',
                    marginLeft: '40px'
                   }}>
                      <span className="view-select-label"> Category Name
                      </span>
                      <span style={{
                      }}>
                          <TextField
                      style={{
                        width:'323px',
                        marginLeft: '67px'
                      }}
                      value={category}
                    />
                      </span>
                   </div>
                </div>
                
                <div style={{display: 'flex', justifyContent:'space-between'}}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    marginBottom: '2rem'

                   }}>
                      <span className="view-select-label"> Status Name
                      </span>
                      <span style={{
                            marginLeft: '89px'
                      }}>
                          <TextField
                      style={{
                        width:'323px'
                      }}
                      value={status}
                    />
                      </span>
                   </div>
                   <div style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    marginBottom: '2rem',

                   }}>
                      <span className="view-select-label-category"> Subcategory Name
                      </span>
                      <span style={{
                      }}>
                          <TextField
                      style={{
                        width:'323px',
                      }}
                      value={subcategory}
                    />
                      </span>
                   </div>
                </div>
                <div style={{display: 'flex', justifyContent:'space-between'}}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    marginBottom: '2rem'

                   }}>
                      <span className="view-select-label"> Type Name
                      </span>
                      <span style={{
                            marginLeft: '98px'
                      }}>
                          <TextField
                      style={{
                        width:'323px'
                      }}
                      value={type}
                    />
                      </span>
                   </div>
                   <div style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    marginBottom: '2rem',
                    marginLeft: '40px'

                   }}>
                      <span className="view-select-label" style={{
                        marginRight:'18px'
                      }}> Market Name
                      </span>
                      <span style={{
                      }}>
                          <TextField
                      style={{
                        width:'323px',
                        marginLeft: '67px'
                      }}
                      value={market}
                    />
                      </span>
                   </div>
                </div>
        </Modal.Body>
    </Modal>
    )
}
export default ViewProductModalOpen;