import React from "react";
import { useNavigate } from "react-router-dom";
import {Row, Col, Button} from 'react-bootstrap'
import { GoogleAuthProvider, signInWithPopup, signOut, } from "firebase/auth";
import { auth } from "../Firebase/Firebase";
import { toast } from "react-toastify";
import GoogleIcon from '@mui/icons-material/Google';
import { userActions } from "../store/user";
import { useDispatch } from "react-redux";

const LoginWithGoogle = ()=>{
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const provider = new GoogleAuthProvider();
  provider.setCustomParameters({ prompt: 'select_account' });  

  const isEmailAllowed = (email) => {
    const allowedDomains = ['milkymist.com', 'resoluteai.in']; // Replace 'example.com' with your desired domain postfix
    return allowedDomains.some((domain) => email.endsWith(`@${domain}`));
  };

  const handleLoginWithGoogle =  async()=>{
    await signInWithPopup(auth, provider)
      .then((result) => {
        // The signed-in user info.
        const {displayName, email} = result.user;
        const user = result.user;
        dispatch(userActions.setUserData(JSON.stringify(user)))
        // console.log(user);
        localStorage.setItem("Email Id", email);
        localStorage.setItem("Display Name", displayName); 
        const userEmail = user.email;


        if(isEmailAllowed(userEmail)){
            navigate('/dashboard');
            toast.success('Signin Successfully');

        }
        else{
              signOut(auth).then(()=>{
              alert("You are not authorised please contact to administration");
              console.log('Invalid email domain. Please use a valid email address.');
              navigate('/');
             })
        }
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  }

  const handleClick = () => {
    handleLoginWithGoogle();
  };
  return (
    <div>

      <Row>
          <Col xs={12}>
              <Button type='button'  className='login-button' 
              style={{background: '#4285f4'}}
              onClick={handleClick}>
              <GoogleIcon style={{
                position:'fixed',
                left:'68%',
              }}/>
              Sign in with Google</Button>
          </Col>
      </Row>
    </div>
  )
}
export default LoginWithGoogle;