import {  Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "../store/modal";
import { updateDoc, doc, getDocs, collection, Timestamp, query, where } from "firebase/firestore";
import { db } from "../Firebase/Firebase";
import { toast } from "react-toastify";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

const formSchema = Yup.object({
    name: Yup.string().trim().required('Status name is required')
})
const EditStatusModalOpen = (props)=>{
    const isEditStatusModalOpen = useSelector((state)=> state.modal.isEditStatusModalOpen);
    const dispatch = useDispatch();
    const {setStatus, statusId, selectedEditRow, setDataApi, dataApi, setSearchTerm} = props;

    const handleEditDoc =  async(newArray)=>{
            try{
               await updateDoc(doc(db, "masters", statusId ),{
                STATUS: newArray
            });
            }
            catch(error){
              console.log(error);
            }
    }
    const updateName = ({id, newName}) => {
            const updatedArr = dataApi.map(item => {
             return item.id === id ? { ...item, name: newName, updatedAt: Timestamp.fromDate(new Date())} : item
            });

            return updatedArr;
      };

      const fetchProductDocument = async (newName)=>{
            try{
                const q = query(collection(db, "products"), where('status', '==', selectedEditRow.name));
                let querySnapshot =  await getDocs(q);
                querySnapshot.forEach(doc=>{
                    updateProductDocument(doc.id, newName);
               })

            }
            catch(error){
                console.log(error);
            }
      }
    const updateProductDocument = async (id, newName)=>{
        try{
            await updateDoc(doc(db, "products", id ),{
              status: newName,
              updatedAt: Timestamp.fromDate(new Date())
            });
           }
          catch(error){
            console.log(error);
          }
    }
    return (
        <Modal 
        size="lg"
        centered
        show={isEditStatusModalOpen} 
        onHide={() => dispatch(modalActions.toggleEditStatusModalHandler())}
    >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{padding: '2rem'}}>
            <div>
                <p className="add-template">Edit Status</p>
            </div>
            <div style={{marginBottom:'2rem'}}>
                <Formik
                    initialValues={{
                         name: selectedEditRow.name
                    }}
                    validationSchema={formSchema}
                    onSubmit={(values, action) => {
                        // same shape as initial values
                        // console.log(values);
                        if(dataApi.some(element=> element.name === values.name)){
                        alert('Please enter unique status name ');
                        action.resetForm();
                        return;
                    }
                    else{

                        const newArray = updateName({id: selectedEditRow.id, newName: values.name});
                        setStatus(newArray);
                        handleEditDoc(newArray)
                        setDataApi(newArray);
                        dispatch(modalActions.toggleEditStatusModalHandler());
                        setSearchTerm('');
                        action.resetForm();
                        toast.success('Edit Status Successfully');
                        fetchProductDocument(values.name)
                    }
                    }}
                    >
                    {({ errors, touched, }) => (
                        <Form>
                        <label className="template-label"> Status Name
                        <Field  type='text' name="name" className="template-name" autoComplete='off'/>
                        </label>
                        {errors.name && touched.name ? (
                            <div style={{
                                marginLeft:'123px',
                                fontFamily: 'IBM Plex Sans',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                fontSize: '20px'
                            }}>{errors.name}</div>
                        ) : null}
                        <Modal.Footer>
                            <div className="submit-button"> 
                               <button type="submit" className="button">Submit</button>
                            </div>
                        </Modal.Footer>
                    </Form>
                    )}
                </Formik>
            </div>
        </Modal.Body>
    </Modal>
    )
}
export default EditStatusModalOpen;