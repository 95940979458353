import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from '../store/modal'
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from 'yup';
import {db} from '../Firebase/Firebase';
import '../../styles/Signin.css';
import {Timestamp, doc, getDoc, updateDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { v4 } from "uuid";
const formSchema = Yup.object({
      brandName: Yup.string().trim().required('Type name is required')
})
const AlertAddTypeModal = (props)=>{

    const {type, setType, setDataApi} = props;
    const dispatch = useDispatch();
    const isAddTypeModalOpen = useSelector(state => state.modal.isAddTypeModalOpen);
    const [uploadedBy, setUploadedBy] = useState("");
    const [isLoading, setIsLoading] = useState(false);


    const  handleType = async (newArray)=>{
        const typeRef = doc(db, 'masters', 'type');
              const typeDoc =  await getDoc(typeRef);
              if(typeDoc.exists()){
                await updateDoc(typeRef, {
                      TYPES: newArray
                })
                setType(newArray);
                setDataApi(newArray);
              }
              else{
                console.log('document exists');
              }
              
       }
    
    const {values, handleChange, handleSubmit, errors} = useFormik({
          initialValues: {
              brandName: ''
          },
          validationSchema: formSchema,
          onSubmit: (values, action)=>{
            if(type.some(element=> element.name === values.brandName)){
                alert('Please enter unique type name ');
                action.resetForm();
                return;
                 
            }
            else{
                const dataStructure = {
                      name: values.brandName,
                      uploadedBy: uploadedBy,
                      createdAt: Timestamp.fromDate(new Date()),
                      updatedAt: Timestamp.fromDate(new Date()),
                      id: v4()
                }
                const newArray = [...type, dataStructure]
                setType(newArray);
                // setDataApi(newArray);
                handleType(newArray);
                action.resetForm();
                dispatch(modalActions.toggleAddTypeModalHandler())
                toast.success('Type Added Successfully');
            }
          }
    })
 
    useEffect(()=>{
      const getName = localStorage.getItem("Display Name");
            setUploadedBy(getName);
            
    },[])
    
    const handleOnHide = ()=>{
      dispatch(modalActions.toggleAddTypeModalHandler());
      errors.brandName = '';
    }
    
    return(
        <Modal 
            size="lg"
            centered
            show={isAddTypeModalOpen} 
            onHide={handleOnHide}
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body style={{padding: '2rem'}}>
                <div>
                  <p className="add-template">Add Type</p>
                </div>
                <div style={{marginBottom:'2rem'}}>
                   <form onSubmit={handleSubmit}>
                        <label className="template-label">Type Name
                            <input type="name" className="template-name" name="brandName" autoComplete="off" value={values.brandName} onChange={handleChange} placeholder="Enter type name"/>
                            {errors.brandName ? <p style={{marginLeft:'115px'}}>{errors.brandName}</p>: null}
                        </label>
                        <Modal.Footer>
                            <div  className="submit-button">
                                <button type="submit" className="button">
                                    {isLoading ? 'Adding Data ..........' : 'Submit'}
                                </button>
                            </div>
                        </Modal.Footer>
                   </form>
               </div>
            </Modal.Body>
        </Modal>
         
    )
}
export default AlertAddTypeModal;