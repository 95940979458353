import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from '../store/modal'
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from 'yup';
import {db} from '../Firebase/Firebase';
import {Timestamp, doc, getDoc, updateDoc, } from "firebase/firestore";
import { v4 } from "uuid";
import '../../styles/Signin.css';
import { toast } from "react-toastify";

const formSchema = Yup.object({
      categoriesName: Yup.string().trim().required('Category name is required')
})
const AlertAddCategoriesModal = (props)=>{

    const {categories, setCategories, setDataApi} = props;
    const dispatch = useDispatch();
    const isAddCategoriesModalOpen = useSelector(state => state.modal.isAddCategoriesModalOpen);
    const [uploadedBy, setUploadedBy] = useState("");
    const [isLoading, setIsLoading] = useState(false);
   
    const  handleCategories = async (newArray)=>{
        const categoriesRef = doc(db, 'masters', 'categories');
              const categoriesDoc =  await getDoc(categoriesRef);
              if(categoriesDoc.exists()){
                 
                await updateDoc(categoriesRef, {
                      CATEGORIES: newArray
                })
                setCategories(newArray);
                setDataApi(newArray);
                console.log('update document sucessfully');
              }
              else{
                console.log('document exists');
              }
              
       }

   
    const {values, handleChange, handleSubmit, errors} = useFormik({
          initialValues: {
              categoriesName: ''
          },
          validationSchema: formSchema,
          onSubmit: (values, action)=>{
            if(categories.some(element=> element.name === values.categoriesName)){
                alert('Please enter unique category name ');
                action.resetForm();
                return;
            }
            else{
                const dataStructure = {
                    name: values.categoriesName,
                    id: v4(),
                    uploadedBy: uploadedBy,
                    createdAt: Timestamp.fromDate(new Date()),
                    updatedAt: Timestamp.fromDate(new Date())
                }
                const newArray = [...categories, dataStructure]
                setCategories(newArray);
                // setDataApi(newArray);
                handleCategories(newArray);
                action.resetForm();
                dispatch(modalActions.toggleAddCategoriesModalHandler())
                toast.success('Category Added Successfully');
            }
          }
    })
 
    useEffect(()=>{
      const getName = localStorage.getItem("Display Name");
            setUploadedBy(getName);
    },[])
    
    const handleOnHide = ()=>{
      dispatch(modalActions.toggleAddCategoriesModalHandler());
      errors.categoriesName = '';
    }
    
    return(
        <Modal 
            size="lg"
            centered
            show={isAddCategoriesModalOpen} 
            onHide={handleOnHide}
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body style={{padding: '2rem'}}>
                <div>
                  <p className="add-template">Add Category</p>
                </div>
                <div style={{marginBottom:'2rem'}}>
                   <form onSubmit={handleSubmit}>
                        <label className="template-label">Category Name
                            <input type="name" className="template-name" name="categoriesName" autoComplete="off" value={values.categoriesName} onChange={handleChange} placeholder="Enter category name"/>
                            {errors.categoriesName ? <p style={{marginLeft:'145px'}}>{errors.categoriesName}</p>: null}
                        </label>
                        <Modal.Footer>
                            <div  className="submit-button">
                                <button type="submit" className="button">
                                    {isLoading ? 'Adding Data ..........' : 'Submit'}
                                </button>
                            </div>
                        </Modal.Footer>
                   </form>
               </div>
            </Modal.Body>
        </Modal>
         
    )
}
export default AlertAddCategoriesModal;