import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "../store/modal";
import { db } from "../Firebase/Firebase";
import { deleteDoc,doc, updateDoc } from "firebase/firestore";
import { toast } from "react-toastify";

const DeleteSubCategoriesModalOpen = (props)=>{
    const isDeleteSubCategoriesModalOpen = useSelector((state)=> state.modal.isDeleteSubCategoriesModalOpen);
    const dispatch = useDispatch();
    const {selectedRow, setSelectedRow, setSubcategories, subcategoriesId, setDataApi, dataApi, setSearchTerm} = props;
     
    const handleDeleteBrand = async()=>{
             const filterData = dataApi.filter((item)=> item.name !== selectedRow.name)
             try{
                await updateDoc(doc(db, "masters", subcategoriesId ),{
                     SUBCATEGORIES: filterData
                });
                dispatch(modalActions.toggleDeleteSubCategoriesModalHandler());
                setSubcategories(filterData);
                setDataApi(filterData)
                setSelectedRow(null);
                setSearchTerm('');
                toast.success("Delete Successfully");
             }
             catch(error){
                console.log(error);
             }
             
       }

    return (
        <>
             <Modal
             centered
             show={isDeleteSubCategoriesModalOpen}
             onHide={()=> dispatch(modalActions.toggleDeleteSubCategoriesModalHandler())}
           >
             <Modal.Header closeButton></Modal.Header>
             <Modal.Body style={{textAlign:'center'}}>
                Are you sure want to delete ?
             </Modal.Body>
             <Modal.Footer>
               <Button variant="primary" 
               onClick={handleDeleteBrand}
               >Confirm</Button>
               <Button variant="primary" 
               onClick={()=> dispatch(modalActions.toggleDeleteSubCategoriesModalHandler())}
               >Cancel</Button>
             </Modal.Footer>
           </Modal>
          
        </>
    )
}
export default DeleteSubCategoriesModalOpen;