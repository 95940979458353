import React, { useEffect, useState } from "react";
import { InputGroup } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { modalActions } from "../store/modal";
import { collection, doc, getDoc, setDoc, getDocs } from "firebase/firestore";

import { db } from "../Firebase/Firebase";
import '../../styles/Signin.css'
import ProductTemplateTable from "./ProductPageTable";
import AlertAddProductModal from "../Modals/AddProductModal";

const ProductPage = () => {
  const dispatch = useDispatch();
  const [products, setProducts] = useState([])
  const [dataApi, setDataApi] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [brandsData, setBrandsData] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [marketsData, setMarketsData] = useState([]);
  const [typeData, setTypeData] = useState([]);
  const [subcategoriesData, setSubcategoriesData] = useState([]);
  const [statusData, setStatusData] = useState([]);
  const [mmdProduct, setMmdProduct] = useState([]);
  const [tag, setTag] = useState(['competitor', 'others']);
  const [tagId, setTagId] = useState('');

  const handleBrandDocument = async () => {
    try {
      const brandsRef = doc(db, 'masters', 'brands');
      const brandsDoc = await getDoc(brandsRef);
      if (brandsDoc.exists()) {
        const brandsData = brandsDoc.data();
        const { BRANDS } = brandsData;

        setBrandsData(BRANDS);
        // console.log('get brand data sucessfully');
      }
      else {
        await setDoc(brandsRef, {
          BRANDS: brandsData
        })
        // console.log('document add sucessfully');
      }
    }
    catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    handleBrandDocument()
  }, [])
  const handleCategoriesDocument = async () => {
    try {
      const categoriesRef = doc(db, 'masters', 'categories');
      const categoriesDoc = await getDoc(categoriesRef);
      if (categoriesDoc.exists()) {
        const categoriesData = categoriesDoc.data();
        const { CATEGORIES } = categoriesData;

        setCategoriesData(CATEGORIES);
      }
      else {
        await setDoc(categoriesRef, {
          CATEGORIES: categoriesData
        })
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    handleCategoriesDocument()
  }, [])

  const handleMarketsDocument = async () => {
    try {
      const marketsRef = doc(db, 'masters', 'markets');
      const marketsDoc = await getDoc(marketsRef);
      if (marketsDoc.exists()) {
        const marketsData = marketsDoc.data();
        const { MARKETS } = marketsData;

        setMarketsData(MARKETS);
      }
      else {
        await setDoc(marketsRef, {
          MARKETS: marketsData
        })
      }
    }
    catch (error) {
      console.log(error);
    }
  }


  useEffect(() => {
    handleMarketsDocument()
  }, [])

  const handleStatusDocument = async () => {
    try {
      const statusRef = doc(db, 'masters', 'status');
      const statusDoc = await getDoc(statusRef);
      if (statusDoc.exists()) {
        const statusData = statusDoc.data();
        const { STATUS } = statusData;

        setStatusData(STATUS);
      }
      else {
        await setDoc(statusRef, {
          STATUS: statusData
        })
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    handleStatusDocument()
  }, [])

  const handleSubcategoriesDocument = async () => {
    try {
      const subcategoriesRef = doc(db, 'masters', 'subcategories');
      const subcategoriesDoc = await getDoc(subcategoriesRef);
      if (subcategoriesDoc.exists()) {
        const subcategoriesData = subcategoriesDoc.data();
        const { SUBCATEGORIES } = subcategoriesData;

        setSubcategoriesData(SUBCATEGORIES);
      }
      else {
        await setDoc(subcategoriesRef, {
          SUBCATEGORIES: subcategoriesData
        })
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    handleSubcategoriesDocument()
  }, [])

  const handleTypeDocument = async () => {
    try {
      const typeRef = doc(db, 'masters', 'type');
      const typeDoc = await getDoc(typeRef);
      if (typeDoc.exists()) {
        const typeData = typeDoc.data();
        const { TYPES } = typeData;

        setTypeData(TYPES);
      }
      else {
        await setDoc(typeRef, {
          TYPES: typeData
        })
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    handleTypeDocument()
  }, [])

  const getTagDocument = async () => {
    try {
      const tagRef = doc(db, 'masters', 'tag');
      const tagDoc = await getDoc(tagRef);
      if (tagDoc.exists()) {
        const tagData = tagDoc.data();
        const { TAG } = tagData;

        setTag(TAG);
        // setTagId(tagDoc.id)
      }
      else {
        await setDoc(tagRef, {
          TAG: tag
        })
      }
    }
    catch (error) {
      console.log(error);
    }

  }

  useEffect(() => {
    getTagDocument()
  }, [])

  const getProducts = async () => {

    try {
      const querySnapshot = await getDocs(collection(db, "products"));
      const data = [];
      const mmdProductCode = [];

      querySnapshot.forEach(doc => {
        if (doc.data().mmd) {
          mmdProductCode.push({ id: doc.id, name: doc.data().name });
        }
        else {
          // console.log('does not exists');
        }
        data.push({ ...doc.data(), id: doc.id });

      })
      setProducts(data);
      setDataApi(data);
      setMmdProduct(mmdProductCode);

    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getProducts();
  }, [])

  const handleDateAndTime = (updatedAt) => {
    if (updatedAt) {
      const date = new Date(updatedAt.seconds * 1000 + updatedAt.nanoseconds / 1000000);
      const formattedDate = date.toLocaleDateString().split('/').join('-');
      const formattedTime = date.toLocaleTimeString('en-US', { hour12: false });
      return {
        formattedDate,
        formattedTime
      }
    }
    else {
      return null
    }
  }
  const handleSearch = (e) => {
    if (e.target.value === '') {
      setProducts(dataApi);
    }
    else {

      const lowerCaseSearchTerm = searchTerm.trim().toLowerCase();

      const filterData = dataApi.filter((item) => {
        const dateAndTime = handleDateAndTime(item.updatedAt);


        let productName = item.name || '';
        let productType = item.mmd === true ? item.mmd.toString() : 'false' || ''
        let createdProductAtDate = dateAndTime !== null ? dateAndTime.formattedDate : '' || ''
        let createdProductAtTime = dateAndTime !== null ? dateAndTime.formattedDate : '' || ''
        let uploaded_by = item.uploadedBy || ''

        return (
          productName.toLowerCase().includes(lowerCaseSearchTerm) ||
          productType.toLowerCase().includes(lowerCaseSearchTerm) ||
          createdProductAtDate.toLowerCase().includes(lowerCaseSearchTerm) ||
          createdProductAtTime.toLowerCase().includes(lowerCaseSearchTerm) ||
          uploaded_by.toLowerCase().includes(lowerCaseSearchTerm)
        )

      });

      setProducts(filterData);

    }
    setSearchTerm(e.target.value);
  }

  return (
    <React.Fragment>
      <div
        style={{
          position: 'fixed',
          top: 110,
          left: 240,
          width: '82%',
          height: '100%',
          overflowY: 'scroll'
        }}
      >
        <div className="manage-templates-header" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '2rem', alignItems: 'center' }}>
          <span className="manage-templates-text">Manage Product</span>
          <span className="add-templates-header" onClick={() => dispatch(modalActions.toggleAddProductModalHandler())} >Add Product <span className="plus">+</span></span>
        </div>
        <InputGroup>
          <input type="search" id="input-placeholder" autoComplete="off" placeholder="Search by product name, mmd, date, time,  uploaded by" value={searchTerm}
            onChange={(e) => handleSearch(e)}
            className="form-control" style={{
              padding: '10px',

              background: '#FFFFFF',
              boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.2)'
            }} />
          <FiSearch
            style={{
              position: 'absolute',
              top: '50%',
              left: '10px',
              transform: 'translateY(-50%)',
              pointerEvents: 'cursor'
            }}
          />
        </InputGroup>
        <div style={{ marginTop: '2rem' }}>
          <ProductTemplateTable products={products} setProducts={setProducts} setDataApi={setDataApi} dataApi={dataApi} setSearchTerm={setSearchTerm} />
        </div>
      </div>
      <AlertAddProductModal products={products} setProducts={setProducts} mmdProduct={mmdProduct} brandsData={brandsData} setBrandsData={setBrandsData} categoriesData={categoriesData} setCategoriesData={setCategoriesData} marketsData={marketsData} setMarketsData={setMarketsData} subcategoriesData={subcategoriesData} setSubcategoriesData={setCategoriesData} statusData={statusData} setStatusData={setStatusData} typeData={typeData} setTypeData={setTypeData} tag={tag} setDataApi={setDataApi} />
    </React.Fragment>
  )
}
export default ProductPage;
