import { createSlice } from "@reduxjs/toolkit";

const mastersSlice = createSlice({
    name: 'masters',
    initialState: {
        mastersData: null,
    },
    reducers:{
        setMastersData: (state, action)=>{
            // state.mastersData = action.payload;
        },
    }
})
export const mastersActions = mastersSlice.actions;
export default mastersSlice.reducer;