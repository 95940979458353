import Router from '../src/components/Routes/Router'
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  return (
    <div className="App">
        <ToastContainer position='top-center'/>
        <Router/>
    </div>
  );
}

export default App;
