import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "../store/modal";
import { db } from "../Firebase/Firebase";
import {doc, updateDoc } from "firebase/firestore";
import { toast } from "react-toastify";

const DeleteBrandModalOpen = (props)=>{
    const isDeleteBrandModalOpen = useSelector((state)=> state.modal.isDeleteBrandModalOpen);
    const dispatch = useDispatch();
    const {selectedRow, setSelectedRow, brands, setBrands, brandsId, setDataApi, dataApi, setSearchTerm} = props;
   //   console.log(brandsId);
    const handleDeleteBrand = async()=>{
             const filterData = dataApi.filter((item)=> item.name !== selectedRow.name)
             try{
                await updateDoc(doc(db, "masters", brandsId ),{
                     BRANDS: filterData
                });
                dispatch(modalActions.toggleDeleteBrandModalHandler());
                setBrands(filterData);
                setDataApi(filterData);
                setSelectedRow(null);
                setSearchTerm('')
                toast.success("Delete Successfully");
             }
             catch(error){
                console.log(error);
             }
       }

    return (
        <>
             <Modal
             centered
             show={isDeleteBrandModalOpen}
             onHide={()=> dispatch(modalActions.toggleDeleteBrandModalHandler())}
           >
             <Modal.Header closeButton></Modal.Header>
             <Modal.Body style={{textAlign:'center'}}>
                Are you sure want to delete ?
             </Modal.Body>
             <Modal.Footer>
               <Button variant="primary" 
               onClick={handleDeleteBrand}
               >Confirm</Button>
               <Button variant="primary" 
               onClick={()=> dispatch(modalActions.toggleDeleteBrandModalHandler())}
               >Cancel</Button>
             </Modal.Footer>
           </Modal>
          
        </>
    )
}
export default DeleteBrandModalOpen;