import { Row } from "react-bootstrap"
import  Card  from "react-bootstrap/Card"
import '../../../styles/Signin.css'

const Card4 = (props)=>{
    const {productData} = props;
    let countMmd = 0;
    productData.forEach((item)=>{
        if(item.mmd){
            countMmd++
        }
    })
    
    return(
        <Card className="fourth-card" style={{background: '#FFF6F5',height:'112px'}}>
             <Card.Body>
                <Card.Title className="fourth-card-title">
                     MMD Product
                </Card.Title>
                <Row>
                    <span className="card-paragraph">
                        {countMmd}
                    </span>
                </Row>
             </Card.Body>
        </Card>
    )
}
export default Card4;