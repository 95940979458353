import {  Row } from "react-bootstrap"
import  Card  from "react-bootstrap/Card"
import '../../../styles/Signin.css'
const Card3 = (props)=>{
    const {productData} = props;
    let countNonMmd = 0;
    productData.forEach((item)=>{
    
        if(!item.mmd){
            // console.log(item);
            countNonMmd++
        }
    })
    return(
        <Card className="third-card" style={{background: '#FFF6F5',height:'112px'}}>
             <Card.Body>
                <Card.Title className="third-card-title">
                     NON-MMD Product
                </Card.Title>
                <Row>
                    <span className="card-paragraph">
                        {countNonMmd}
                    </span>
                </Row>
             </Card.Body>
        </Card>
    )
}
export default Card3;