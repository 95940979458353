import React, { useEffect, useState } from "react";
import {InputGroup} from "react-bootstrap";
import {FiSearch} from "react-icons/fi";
import { useDispatch } from "react-redux";
import { modalActions } from "../store/modal";
import {collection, doc, getDoc, setDoc,getDocs } from "firebase/firestore";

import { db } from "../Firebase/Firebase";
import '../../styles/Signin.css'
import AlertAddSubCategoriesModal from "../Modals/AddSubCategoriesModal";
import SubCategoriesTable from "./SubCategoriesTable";

const SubCategories = ()=>{
    const dispatch = useDispatch();
    const [subcategories, setSubcategories]  = useState([]);
    const [subcategoriesId,setSubcategoriesId] = useState("");
    const [dataApi, setDataApi] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const getSubcategoriesDocument = async ()=>{
      try{
        const subcategoriesRef = doc(db, 'masters', 'subcategories');
          const subcategoriesDoc =  await getDoc(subcategoriesRef);
          if(subcategoriesDoc.exists()){
            const subcategoriesData = subcategoriesDoc.data();
            const {SUBCATEGORIES} = subcategoriesData;
             
            setSubcategories(SUBCATEGORIES);
            setSubcategoriesId(subcategoriesDoc.id)
            setDataApi(SUBCATEGORIES)
            // console.log('get subcategories data sucessfully');
          }
          else{
            await setDoc(subcategoriesRef, {
                  SUBCATEGORIES: []
            })
            // console.log('document add sucessfully');
            setSubcategoriesId(subcategoriesDoc.id);
          }
      }
      catch(error){
        console.log(error);
      }

       
    }
    
    useEffect(()=>{
           getSubcategoriesDocument()
    },[])

    const handleSearch = (e)=>{
          if(e.target.value === ''){
            setSubcategories(dataApi);
          }
          else{

              const lowerCaseSearchTerm = searchTerm.trim().toLowerCase();
              
              const filterData = dataApi.filter((item)=> {
                const date = new Date(item.updatedAt.seconds * 1000 + item.updatedAt.nanoseconds / 1000000);
                // console.log(date);
                const formattedDate = date.toLocaleDateString().split('/').join('-');
                const formattedTime = date.toLocaleTimeString('en-US', { hour12: false });

                      let brandName = item.name || '';
                      let createdBrandAtDate = formattedDate || ''
                      let createdBrandAtTime = formattedTime || ''
                      let uploaded_by = item.uploadedBy || ''
                  
                  return (
                    brandName.toLowerCase().includes(lowerCaseSearchTerm) ||
                    createdBrandAtDate.toLowerCase().includes(lowerCaseSearchTerm) ||
                    createdBrandAtTime.toLowerCase().includes(lowerCaseSearchTerm) ||
                    uploaded_by.toLowerCase().includes(lowerCaseSearchTerm)
                  )
                    
              });

                setSubcategories(filterData);
          }
          setSearchTerm(e.target.value);
    }

    return (
        <React.Fragment>
            <div
            style={{
               position:'fixed',
               top:110,
               left:240,
               width:'82%',
               height:'100%',
               overflowY: 'scroll'
            }} 
            
            >
                  
                  <div className="manage-templates-header"  style={{display: 'flex',justifyContent: 'space-between', marginBottom:'2rem', alignItems:'center'}}>
                    <span className="manage-templates-text">Manage Subcategory</span>
                    <span className="add-templates-header" onClick={()=> dispatch(modalActions.toggleAddSubCategoriesModalHandler())} >Add Subcategory <span className="plus">+</span></span>
                  </div>
              
                  <InputGroup>
                    <input type="search" id="input-placeholder" autoComplete="off" placeholder="Search by subcategory name, date, time, uploaded by" value={searchTerm} 
                    onChange={(e)=>handleSearch(e)} 
                    className="form-control" style={{padding: '10px',
                    background: '#FFFFFF',
                    boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.2)'}}/>
                    <FiSearch
                     style={{
                          position:'absolute',
                          top:'50%',
                          left:'10px',
                          transform: 'translateY(-50%)',
                          pointerEvents: 'cursor'
                     }}
                    />
                  </InputGroup>
              
              <div style={{marginTop:'2rem'}}>
                  <SubCategoriesTable subcategories={subcategories} setSubcategories={setSubcategories} subcategoriesId={subcategoriesId} setDataApi={setDataApi} dataApi={dataApi} setSearchTerm={setSearchTerm}/>
              </div>
            </div>
            <AlertAddSubCategoriesModal subcategories={subcategories} setSubcategories={setSubcategories} subcategoriesId={subcategoriesId} setDataApi={setDataApi}/>
        </React.Fragment>
    )
}
export  default SubCategories;
