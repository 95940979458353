import React, { useEffect, useState } from 'react';
import Logo from '../../../assets/logo/FaceGenie logo.png'
import '../../../styles/Signin.css'
import { Box } from '@mui/material';
import Avatar from '@mui/material/Avatar';
function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }
  
  function stringAvatar(name) {
    const avatarInitials = name.includes(' ')
    ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
    : name[0];
     
    
    return {
      sx: {
        bgcolor: stringToColor(avatarInitials),
        // top: '4px'
      },
      children: avatarInitials,
    };
  }
const Navbar = ()=>{

    const [userEmail, setUserEmail] = useState("");
    useEffect(()=>{
        const getEmail = localStorage.getItem("Email Id");
        const auth = sessionStorage.getItem("email");
        auth ? setUserEmail(auth) : setUserEmail(getEmail)
    },[])
    const display_name = localStorage.getItem("Display Name");
    
    return(
         <Box
            style={{
                position: 'fixed',
                left:'0',
                right:'0',
                top:'0',
                display:'flex',
                justifyContent:'space-between',
                alignItems:'center',
                padding: '20px 20px',
                zIndex:'999',
                backgroundColor:'#ffff',
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"
            }}
          >

            <img src={Logo} alt="FACEGENIE" width={160} />
            <div style={{display:'flex',
             gap:'5px',
             justifyContent:'space-between',
             alignItems: 'center'
            }}>
              <div className='userId'>{userEmail}</div>
              <div style={{marginRight:'15px'}}>
              <Avatar  
              style={{
                height:'35px',
                width: '35px',
                fontFamily: 'IBM Plex Sans'

              }}
              {...stringAvatar(display_name ? display_name : 'Guest' )} />
              </div>
            </div>
         </Box>
    )
}
export default Navbar;