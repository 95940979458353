import { configureStore } from "@reduxjs/toolkit";
import modalReducer from "./modal";
import userReducer from './user';
import templateReducer from './templatesData';
import mastersReducer from './mastersData';

const store = configureStore({
    reducer:{
        modal: modalReducer,
        user:  userReducer,
        template: templateReducer,
        masters: mastersReducer
    }
})
export default store;
 