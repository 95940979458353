import {Route, Routes} from 'react-router-dom';
import SigninPage from '../SigninPage/SignPage';
import Dashboard from '../HomePage/Dashboard/Dashboard';
import ManageTemplates from '../HomePage/ManageTemplates/ManageTemplates';
import CommonLayout from './CommonLayout';
import Settings from '../HomePage/Settings/Settings';
import ProductPage from '../Products/ProductPage';
import Brands from '../Masters/Brands';
import Categories from '../Masters/Categories';
import Market from '../Masters/Market';
import Status from '../Masters/Status';
import SubCategories from '../Masters/SubCategories';
import Type from '../Masters/Type';

const Router = ()=>{
    return (

        <Routes>
             <Route index element={<SigninPage/>}/>
             <Route path='/dashboard' element={<CommonLayout><Dashboard/></CommonLayout>}/>
             <Route path='/manage-templates' element={<CommonLayout><ManageTemplates/></CommonLayout>}/>
             <Route path='/product' element={<CommonLayout><ProductPage/></CommonLayout>}/>
             {/* <Route path='/settings' element={<CommonLayout><Settings/></CommonLayout>}/> */}
             <Route path='/brands' element={<CommonLayout><Brands/></CommonLayout>}/>
             <Route path='/categories' element={<CommonLayout><Categories/></CommonLayout>}/>
             <Route path='/market' element={<CommonLayout><Market/></CommonLayout>}/>
             <Route path='/status' element={<CommonLayout><Status/></CommonLayout>}/>
             <Route path='/sub-categories' element={<CommonLayout><SubCategories/></CommonLayout>}/>
             <Route path='/type' element={<CommonLayout><Type/></CommonLayout>}/>
             <Route path='/logout' element={<CommonLayout></CommonLayout>}/>
             
        </Routes>
    )
}
export default Router;