import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from '../store/modal'
import { useEffect, useState } from "react";
import {db} from '../Firebase/Firebase';
import {collection, addDoc, query, where, getDocs, doc, updateDoc, Timestamp} from "firebase/firestore";
import {storage} from '../Firebase/Firebase';
import {ref, uploadBytes, getDownloadURL, listAll } from 'firebase/storage';
import { v4 } from "uuid";
import '../../styles/Signin.css';
import { toast } from "react-toastify";
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import { updateTemplatesCollection } from "../HomePage/ManageTemplates/UpdateTemplates";

const AlertAddTemplatesModal = (props)=>{

    const {setTemplates, productData, setDataApi} = props;
    const dispatch = useDispatch();
    const isAddtemplatesModalOpen = useSelector(state => state.modal.isAddTemplatesModalOpen);
    const [uploadedBy, setUploadedBy] = useState("");
    const [templatesName, setTemplatesName] = useState("");
    const [selecteFile, setSelecteFile] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectProduct, setSelectProduct] = useState("");
    const [templateProduct, setTemplateProduct] = useState("");
    const[productTemplateId, setProductTemplateId] = useState("");
    const [templateStatus, setTemplateStatus] = useState(true);
    const [newTemplateArray, setNewTemplateArray] = useState([]);
    const [templateImageFolderVersionName, setTemplateImageFolderVersionName] = useState('');
    const [selectedProductFolderNameForTemplate, setSelectedProductFolderNameForTemplate] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
     
    const addProductVersion = async(productId, newTemplateArray)=>{
            try{
              await updateDoc(doc(db, "products", productId), {
              product_version: newTemplateArray,
              updatedAt: Timestamp.fromDate(new Date())
              })
             }
              catch(error){
                console.log(error);
              }
                
    }

    const handleInputChangeProduct = (event, newValue)=>{
          if(newValue === ''){
            setSelectProduct('');
            setSelectedOption(null);
          }
          if(newValue){
            const string = newValue;
            const lastIndex = string.lastIndexOf("-");
            const searchProductName = string.substring(0, lastIndex).trim();
            if(productData.some(item => item.name === searchProductName)){
              setTemplateProduct(searchProductName);
              setSelectedProductFolderNameForTemplate(searchProductName);
              getUpdate(searchProductName);
            }
          }
    }
    
      const getUpdate = async(searchProductName)=>{
          const q = query(collection(db, "products"), where('name', '==', searchProductName));
               try{
                  let querySnapshot =  await getDocs(q);
                  const productTemplateName = querySnapshot.docs[0].data();
                  const productId = querySnapshot.docs[0].id;
                  setProductTemplateId(productId);
                  if(!productTemplateName.product_version){
                     // update the doc
                    const templateArray = [];
                    const autoGenerateTemplateName = `${productId}-V1`;
                    templateArray.push(autoGenerateTemplateName);
                    setSelectProduct(autoGenerateTemplateName);
                    setNewTemplateArray(templateArray)
                    addProductVersion(productId, templateArray);
                    setTemplateImageFolderVersionName('V1');
                  }
                   
                  else if(productTemplateName.product_version &&  productTemplateName.product_version.length === 0){
                    const templateArray = productTemplateName.product_version;
                    const autoGenerateTemplateName = `${productId}-V1`;
                    templateArray.push(autoGenerateTemplateName);
                    setSelectProduct(autoGenerateTemplateName);
                    setNewTemplateArray(templateArray);
                    setTemplateImageFolderVersionName('V1');
    
                  }
                  else if(productTemplateName.product_version){
                      const templateArray = productTemplateName.product_version;
                      const autoTemplateName = templateArray[templateArray.length-1];
                      
                      let hyphenIndex =  autoTemplateName.lastIndexOf("-");
                      let extractedPrefix = autoTemplateName.substring(0, hyphenIndex + 1);
                      let extractedV =  autoTemplateName.substring(hyphenIndex + 1);
                      
                      let numericPart = parseInt(extractedV.slice(1)); // Extract numeric part and convert it to an integer
                      let convertedString = "V" + (numericPart + 1); // Increment the numeric part and concatenate with "V"
                      setTemplateImageFolderVersionName(convertedString);
                      const autoGenerateTemplateName = extractedPrefix + convertedString;
                      // console.log(autoGenerateTemplateName);
                      templateArray.push(autoGenerateTemplateName);
                      setSelectProduct(autoGenerateTemplateName);
                      setNewTemplateArray(templateArray);
                  }
               }
               catch(error){
                console.log(error);
               }
            }
    
     
    useEffect(()=>{
      const getName = localStorage.getItem("Display Name");
            setUploadedBy(getName);
    },[])
    
  const handleOnchangeProduct = (event, value)=>{
        setSelectedOption(value)
  }
    const handleInputKeyDown = (event) => {
      // console.log(selectedOption, 'selected option');
      if (selectedOption) {
        event.preventDefault();
      }
    };
    const handleInputKeyPress = (event)=>{
          if(selectedOption){
            event.preventDefault();
          }
    }
 
    //  uploaded images
    const handleImageSelect = (event) => {
      const files = event.target.files;
      const updatedImages = Array.from(files).slice(0,8);
      setSelecteFile(updatedImages);
    };

    const handleImageDeselect = (index) => {
      setSelecteFile((prevImages) => {
      const updatedImages = [...prevImages];
      updatedImages.splice(index, 1);
      return updatedImages;
      });
    };
    let data = []
    const handleImageUpload = async() => {
      const folderRef = ref(storage, `Templates/${selectedProductFolderNameForTemplate}/${templateImageFolderVersionName}`);
          for(let i =0; i< selecteFile.length; i++){
              const file = selecteFile[i];
              // console.log(file);
              const imageRef = ref(folderRef, `image_${i+1}`);
              try{
                await uploadBytes(imageRef, file);
              }
              catch(error){
                console.error('Error uploading file', error);
              }
          }
          // download image from storage
      const imageRefs = await listAll(folderRef);

      const downloadPromises = imageRefs.items.map(async (itemRef, index) => {
        const downloadUrl = await getDownloadURL(itemRef);
        return {
          id: v4(),
          url: downloadUrl,
          index: index + 1
        };
      });

      const downloadUrls = await Promise.all(downloadPromises);
          return downloadUrls;
    }
    const handleOnHide = ()=>{
      dispatch(modalActions.toggleAddTemplatesModalHandler());
      setSelecteFile([]);
      setSelectProduct("");
      setTemplateProduct("");
      setSelectedOption(null);
    }

     // Reference refer to the collection
    const collectionRefForTemplates = collection(db, "templates");
    
    // add data
    const addData = async ()=>{
      if(selectProduct === ''){
        alert('Please select product');
        return;
      }
      setIsLoading(true)
      const imagesLink = await handleImageUpload();
      // console.log(imagesLink, 'selected  images');
      const dataObject = {
        name: selectProduct,
        uploadedBy: uploadedBy,
        images: imagesLink,
        product: templateProduct,
        productId: productTemplateId,
        status: templateStatus,
        createdAt: Timestamp.fromDate(new Date()),
        updatedAt: Timestamp.fromDate(new Date()),
      }  
      try{
        await addDoc(collectionRefForTemplates, {...dataObject});
        getTemplatesData();
        setIsLoading(false);
        dispatch(modalActions.toggleAddTemplatesModalHandler());;
        updateTemplatesCollection();
        setTemplatesName("")
        setSelecteFile([]);
        setSelectedOption(null)
        data = [];
        toast.success(" Template Added Successfully")
        setSelectProduct("");
        updateDocument();
      }
      catch(error){
        console.log(error);
      }
   }

   const updateDocument = async ()=>{
      try{
          await updateDoc(doc(db, "products", productTemplateId), {
          product_version: newTemplateArray
          })
      }
      catch(error){
        console.log(error);
      }
   }

   const getTemplatesData = async()=>{
    try{
      const querySnapshot = await getDocs(collection(db, "templates"));
      const newData = [];
      querySnapshot.forEach(doc => {
        newData.push({...doc.data(), id:doc.id});
         
        })
      setTemplates(newData);
      setDataApi(newData);
      }
      catch(error){
      console.log(error);
      }
   }
   
    return(
        <Modal 
            size="lg"
            centered
            show={isAddtemplatesModalOpen} 
            onHide={handleOnHide}
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body style={{padding: '2rem'}}>
                  <div>
                  <p className="add-template">Add Template</p>
                   <div style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                   }}>
                      <span className="select-label">Select Product
                      </span>
                      <span style={{
                            marginLeft: '35px'
                      }}>
                        <Stack spacing={2} sx={{ 
                            width: '320px',
                            background: '#FFFFFF',
                            borderRadius: '5px',
                          }}>
                              <Autocomplete
                                freeSolo
                                id="free-solo-2-demo"
                                disableClearable 
                                value={selectedOption}
                                onChange={handleOnchangeProduct}
                                onInputChange={handleInputChangeProduct}
                                options={productData.map((option) => `${option.name} - ${option.id}`)}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Select / Search Product"
                                    InputProps={{
                                      ...params.InputProps,
                                      type: 'search',
                                      style: { cursor: 'default' }, // Hide the cursor to indicate non-editable
                                      onKeyDown: handleInputKeyDown,
                                      onKeyPress: handleInputKeyPress
                                    }}
                                  />
                                )}
                              />
                            </Stack>
                      </span>
                   </div>
                 </div>
                <div style={{marginBottom:'2rem'}}>
                  <label className="template-label">Template Name
                      <input type="text" className="template-name"  value={selectProduct} 
                      readOnly
                      />
                  </label>
                <br/>
           </div>
             <div>
             <input type="file" multiple onChange={handleImageSelect} />
             <p className="text-download">Maximum 8 images can be uploaded</p>

              <div className="preview-container">
                {selecteFile.map((image, index) => (
                  <button key={index} className="image-thumbnail" onClick={() => handleImageDeselect(index)}>Deselect
                    <img src={URL.createObjectURL(image)} alt={`Preview ${index}`} />
                  </button>
                ))}
              </div>
             </div>
            </Modal.Body>
            <Modal.Footer>
          <div  className="submit-button">
            <button type="submit" className="button"  onClick={addData} 
            disabled ={
               !templateProduct
               ||
               selecteFile.length === 0 
               || selectProduct === ''
              }
            >
                {isLoading ? 'Adding Data ..........' : 'Submit'}
              </button>
          </div>
        </Modal.Footer>
        </Modal>
    )
}
export default AlertAddTemplatesModal;