import { Row } from "react-bootstrap"
import  Card  from "react-bootstrap/Card"
import '../../../styles/Signin.css'

const Card1 = (props)=>{
    const {templateData} = props;

    return(
        <Card className="first-card" style={{background: '#FFF6F5', height:'112px'}}>
             <Card.Body style={{width:'200px'}}>
                <Card.Title className="first-card-tilte" style={{fontFamily: 'IBM Plex Sans'}}>
                    Total  Template
                </Card.Title>
                <Row>
                    <span className="card-paragraph">
                        {templateData.length}
                    </span>
                </Row>
             </Card.Body>
        </Card>
    )
}
export default Card1;
 