import {Modal} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "../store/modal";
const ViewTableDataModalOpen = (props)=>{
    const isViewTableDataModalOpen = useSelector((state)=> state.modal.isViewTableDataModalOpen);
    const dispatch = useDispatch();
    const {selectedViewRow, setSelectedViewRow} = props;
    const {name, product, time, date, uploadedBy} = selectedViewRow;
     
    return (
        <Modal 
        size="lg"
        centered
        show={isViewTableDataModalOpen} 
        onHide={() => dispatch(modalActions.toggleViewTableDataModalHandler())}
    >
        <Modal.Header closeButton ></Modal.Header>
        <Modal.Body style={{padding: '2rem'}}>
         <div>
            <p className="add-template"> Template Details</p>
            <label className="template-label">Template Name
                <input type="text" className="template-name" 
                 value={name} 
                 readOnly
                />
            </label>
            <br/>
            <label className="select-label"> Product Name
                    <input type="text" value={product} readOnly className="select-dropdown" style={{marginTop:'15px'}}/>
            </label>
         </div>
        </Modal.Body>
    </Modal>
    )
}
export default ViewTableDataModalOpen;