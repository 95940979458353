import {  Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "../store/modal";
import { useState } from "react";
import { updateDoc, doc, getDocs, collection, Timestamp } from "firebase/firestore";
import { db } from "../Firebase/Firebase";
import { toast } from "react-toastify";
import {storage} from '../Firebase/Firebase';
import {ref, uploadBytes, getDownloadURL,listAll, deleteObject } from 'firebase/storage';
import { updateTemplatesCollection } from "../HomePage/ManageTemplates/UpdateTemplates";
import { v4 } from "uuid";

const EditTableDataModalOpen = (props)=>{
    const isEditTableDataModalOpen = useSelector((state)=> state.modal.isEditTableDataModalOpen);
    const [selecteFile, setSelecteFile] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    
    const dispatch = useDispatch();
    const {setTemplates, selectedEditRow, setSelectImagesRow, selectImagesRow, setDataApi, setSearchTerm} = props;
    const {name, product} = selectedEditRow;
 
  // select images 
       const handleImageSelect = (event) => {
        const files = event.target.files;
        const updatedImages = Array.from(files).slice(0,8);
        setSelecteFile(updatedImages);
      };
  
      const handleImageDeselect = (index) => {
        setSelecteFile((prevImages) => {
        const updatedImages = [...prevImages];
        updatedImages.splice(index, 1);
        return updatedImages;
        });
      };
      
      const handleFileName = (imageIndex) =>{
          let flag = 0;
          let upadteIndex = imageIndex;
            for(let i =0; i < selectImagesRow.length; i++){
              if(selectImagesRow.some(item => item.index === imageIndex)){
                imageIndex++;
                upadteIndex = imageIndex;
              }
              else{
                flag = 1;
                break;
              }
              
            }
          if(flag === 1){ 
            // console.log(imageIndex, 'image index');
            return imageIndex;
          }
          else{
            // console.log(upadteIndex, 'update index');
            return upadteIndex;
          }
      }
      const handleImageUpload = async() => {
      selectImagesRow.sort((a, b) => a.index - b.index);
      const imageDetails = [...selectImagesRow];
      const existingImageCount = selectImagesRow.length;
      const string = selectedEditRow.name;
      let hyphenIndex =  string.lastIndexOf("-");
      let extractedPrefix = string.substring(0, hyphenIndex + 1);
      let extractedV =  string.substring(hyphenIndex + 1);
      const folderRef = ref(storage, `Templates/${selectedEditRow.product}/${extractedV}`);
            
            for(let i =0; i< selecteFile.length; i++){
                const file = selecteFile[i];
                let imageIndex =  i + 1;
                
              const imageIndexName =   handleFileName(imageIndex);
               console.log(imageIndexName, 'image index name');
                const uniqueId1 = v4();
                const imageRef = ref( folderRef, `image_${imageIndexName}`);
                try{
                  const uploadResult =  await uploadBytes(imageRef, file);
                  const downloadUrl = await getDownloadURL(uploadResult.ref);

                  // Store image details in Firestore
                  const imageDetail = { index: imageIndexName, url: downloadUrl, id: uniqueId1 };
                  imageDetails.push(imageDetail);
                  selectImagesRow.push(imageDetail)
                }
                catch(error){
                  console.error('Error uploading file', error);
                }
            }

  // Sort all images based on index to ensure correct sequential order
            imageDetails.sort((a, b) => a.index - b.index);

            return imageDetails;
      }


      // delete Images
      const deleteImageByUrl = async (imageUrl) => {
        try {
          // Extract the image path from the URL
          const imagePath = getImagePathFromUrl(imageUrl);
          // console.log(imagePath, 'image path');
          // Get a reference to the image file
          const imageRef = ref(storage, imagePath)
          // console.log(imageRef, 'image reference');
          // console.log(imageRef.fullPath, 'folder  path');
          const folderPath = imageRef.fullPath;
          const folderRef = ref(storage, folderPath);
          // Get a list of files within the folder
          const fileList = await listAll(folderRef);

          // Delete each file within the folder
          const deleteFilePromises = fileList.items.map((file) =>
            deleteObject(ref(storage, `${folderPath}/${file.name}`))
          );
          await Promise.all(deleteFilePromises);

          // Delete the empty folder
          await deleteObject(folderRef);
          // Delete the image file
          // await imageRef.delete();
      
          console.log('Image deleted successfully.');
        } catch (error) {
          console.error('Error deleting image:', error);
        }
      };
      function getImagePathFromUrl(imageUrl) {
        // console.log(imageUrl, 'image url');
        // Split the URL by the bucket URL
        const parts = imageUrl.split('https://storage.googleapis.com/');
        // console.log(parts, 'parts');
        // Extract the path after the bucket URL
        const path = parts[0];
        // console.log(path, 'path');
        // Return the image path
        return path;
      }

      // ----------------------------------------------------------
         const handleDeleteImage = (image)=>{
          // console.log(image);
            if(selectImagesRow.length === 1 && selecteFile.length === 0){
               alert('You can not delete this image please add at least one image');
               return;
            }
             else{
              const filterImage = selectImagesRow.filter(item => item.id !== image.id);
              // console.log(filterImage);
              setSelectImagesRow(filterImage);
              deleteImageByUrl(image.url);
              updateSelectedDocument(filterImage);
             }
         }
         
         const updateSelectedDocument = async (filterImage)=>{
                try{
                  await updateDoc(doc(db, "templates", selectedEditRow.id),{
                  updatedAt: Timestamp.fromDate(new Date()),
                  images: filterImage
                });
                updateTemplatesCollection()
                }
                catch(error){
                  console.log(error);
                }
      }
  // --------------------------------------------------------------
    const handleEditDoc =  async()=>{
           setIsLoading(true)
           let getImagesTemplateFolder = await handleImageUpload();

            if(getImagesTemplateFolder.length === 0){
            alert('Please upload at least one image');
            setIsLoading(false);
            return
           }
           else{
                try{
                      await updateDoc(doc(db, "templates", selectedEditRow.id),{
                      updatedAt: Timestamp.fromDate(new Date()),
                      images: getImagesTemplateFolder
                    });
                    updateTemplatesCollection()
                    dispatch(modalActions.toggleEditTableDataModalHandler());
                 }
                 catch(error){
                  console.log(error);
                 }

                const handleUpdatedData = async()=>{
                      try{
                      const querySnapshot = await getDocs(collection(db, "templates"));
                      const newData = [];
                      querySnapshot.forEach(doc => {
                        newData.push({...doc.data(), id:doc.id});
                        })
                      setTemplates(newData);
                      setDataApi(newData);
                      setSearchTerm('');
                      }
                      catch(error){
                      console.log(error);
                      }
                  }
                  handleUpdatedData()
                  setIsLoading(false)
                  setSelecteFile([])
                  toast.success("Edit Template Successfully");
         }
          
    }
    // handle Images when modal hide
    const handleImagesOnModalHide = async()=>{
              try{
                await updateDoc(doc(db, "templates", selectedEditRow.id),{
                images: selectImagesRow
              });
          }
          catch(error){
            console.log(error);
          }

          const handleUpdatedData = async()=>{
            try{
            const querySnapshot = await getDocs(collection(db, "templates"));
            const newData = [];
            querySnapshot.forEach(doc => {
              newData.push({...doc.data(), id:doc.id});
               
              })
            setTemplates(newData);
            setDataApi(newData);
            }
            catch(error){
            console.log(error);
            }
        }
        handleUpdatedData()
    }
    const handleOnHide = ()=>{
          handleImagesOnModalHide()
          setSelecteFile([]);
          dispatch(modalActions.toggleEditTableDataModalHandler());
    }
   

    return (
        <Modal 
        size="lg"
        centered
        show={isEditTableDataModalOpen} 
        onHide={handleOnHide}
    >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{padding: '2rem'}}>
            <div>
            <p className="add-template">Edit / Add Image </p>
            <label className="template-label">Template Name
                <input type="text" className="template-name" 
                name="name"
                value={name} 
                disabled
                readOnly
                />
            </label>
            <br/>
            <label className="select-label"> Product Name
                    <input  value={product} readOnly className="select-dropdown" style={{marginTop:'15px'}}
                    />
            </label>
       </div>
       <div>
             <input type="file" multiple onChange={handleImageSelect} />
             <p className="text-download">Maximum 8 images can be uploaded</p>
              <div className="preview-container">
                {selecteFile.map((image, index) => (
                  <button key={index} className="image-thumbnail" onClick={() => handleImageDeselect(index)}>Deselect
                    <img src={URL.createObjectURL(image)} alt={`Preview ${index}`} />
                  </button>
                ))}
              </div>
             <hr/>
              <div className="preview-container">
                {selectImagesRow ? selectImagesRow.map((image, index) => (
                  <button key={index} className="image-thumbnail" onClick={() => handleDeleteImage(image)}>Delete
                    <img src={image.url} alt={`Preview ${index}`} />
                  </button>
                )): null}
              </div>

        </div>
        </Modal.Body>
        <Modal.Footer>
      <div  className="submit-button">
        <button type="submit" className="button"
        onClick={handleEditDoc}
        > 
        {isLoading ? 'Loading........' : 'Submit'}
        </button>
      </div>
    </Modal.Footer>
    </Modal>
    )
}
export default EditTableDataModalOpen