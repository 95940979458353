import React from "react";
import { Col, Row } from "react-bootstrap"
import {FiUsers, FiLogOut, FiSettings} from "react-icons/fi";
import {BsReverseLayoutTextWindowReverse} from "react-icons/bs";
import { NavLink, useLocation} from "react-router-dom";
import '../../styles/Signin.css';
import { useDispatch } from "react-redux";
import { modalActions } from "../store/modal";
import LogoutModal from "../Modals/LogoutModal";
import { MdOutlineInventory2 } from "react-icons/md";
import SubMenu from "./Submenu";
import { SidebarData } from "./SidebarData";
 

const Sidebar = ()=>{
  const location = useLocation();
  const dispatch = useDispatch();
 

    return (
        <React.Fragment>
            <div className="div" style={{width:240, position:"fixed", left:'-35px',top:'110px'}}>   
                <Row className=" home-dashboard"  style={{marginRight:'35px'}}>
                    <Col className="dashboard"  id= {`${location.pathname==='/dashboard' || location.pathname==='/' ? 'active_class_dashboard':null}`}> 
                        <NavLink    to='/dashboard' className= {`${location.pathname==='/dashboard' || location.pathname==='/' ? 'active_class':"unactive" } text_decoration_none`  }>
                            <BsReverseLayoutTextWindowReverse/>
                            <span style={{marginLeft:'10px'}}>Dashboard</span>
                        </NavLink>
                    </Col>
                    <Col className="manage-templates" id= {`${location.pathname==='/manage-templates' ? 'active_class_dashboard':null}`}>
                        <NavLink to='/manage-templates' className= {`${location.pathname==='/manage-templates'? 'active_class': 'unactive'} text_decoration_none `}>
                            <FiUsers/>
                            <span style={{marginLeft:'10px'}}>Manage Template</span>
                        </NavLink>
                    </Col>
                    <Col className="manage-templates" id= {`${location.pathname==='/product' ? 'active_class_dashboard':null}`}>
                        <NavLink to='/product' className= {`${location.pathname==='/product'? 'active_class': 'unactive'} text_decoration_none `}>
                            {/* <FiUsers/> */}
                            <MdOutlineInventory2/>
                            <span style={{marginLeft:'10px'}}>Manage Product</span>
                        </NavLink> 
                    </Col>
                     
                        {SidebarData.map((item, index) => {
                        return  <SubMenu item={item} key={index} />
                
                        })}
                    {/* <Col className="settings" id= {`${location.pathname==='/settings' ? 'active_class_dashboard':null}`}>
                        <NavLink to='/settings' className= {`${location.pathname==='/settings'? 'active_class': 'unactive'} text_decoration_none `}>
                            <FiSettings/>
                            <span style={{marginLeft:'10px'}}>Settings</span>
                        </NavLink>
                    </Col> */}
                    <Col className="logout" onClick={()=> dispatch(modalActions.toggleLogoutModalHandler())} id= {`${location.pathname==='/logout' ? 'active_class_dashboard':null}`}>
                        
                        <NavLink to='/logout' className= {`${location.pathname==='/logout'? 'active_class': 'unactive'} text_decoration_none `}>
                        <FiLogOut/>
                        <span style={{marginLeft:'10px'}}>Logout</span>
                        </NavLink>
                        
                    </Col>
                </Row>
                <LogoutModal/>
            </div>
        </React.Fragment>
    )
}
export default Sidebar;