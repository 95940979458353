import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { modalActions } from '../store/modal';
import ViewMarketModalOpen from '../Modals/ViewMarketModal';
import DeleteMarketModalOpen from '../Modals/DeleteMarketModal';
import EditMarketModalOpen from '../Modals/EditMarketModal';
import EditIcon from '@mui/icons-material/Edit';

// styling the table heading
const useStyles = makeStyles({
  header: {
    backgroundColor: '#F33823', // Set the background color here
  },
  table: {
    background: '#FFFCFB',
    boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.2)',
    borderRadius: '5px'
  },
  tableHeader: {
    color: 'white'
  }
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const handleDateAndTime = (updatedAt) => {
  const date = new Date(updatedAt.seconds * 1000 + updatedAt.nanoseconds / 1000000);
  const formattedDate = date.toLocaleDateString().split('/').join('-');
  const formattedTime = date.toLocaleTimeString('en-US', { hour12: false });
  return {
    formattedDate,
    formattedTime
  }
}

function stableSort(array, comparator) {
  const newArray = array.map((item) => {
    const dateAndTime = handleDateAndTime(item.updatedAt);
    return {
      name: item.name,
      uploadedBy: item.uploadedBy,
      date: dateAndTime.formattedDate,
      time: dateAndTime.formattedTime,
      updatedAt: item.updatedAt,
      id: item.id
    }
  })
  const stabilizedThis = newArray.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Market  Name',
  },
  {
    id: 'date',
    numeric: false,
    disablePadding: false,
    label: 'Date',
  },
  {
    id: 'time',
    numeric: false,
    disablePadding: false,
    label: 'Time',
  },
  {
    id: 'uploadedBy',
    numeric: false,
    disablePadding: false,
    label: 'Uploaded by',
  },
  {
    id: 'blank',
    numeric: false,
    disablePadding: false,
    label: '',
  }

];

const DEFAULT_ORDER = 'asc';
const DEFAULT_ORDER_BY = 'name';
const DEFAULT_ROWS_PER_PAGE = 5;


function EnhancedTableHead(props) {
  const classes = useStyles();
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead  >
      <TableRow className={classes.header} >
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}

          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              style={{ color: 'white', fontSize: '1rem' }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function MarketTable(props) {
  const { markets, setMarkets, marketsId, setDataApi, dataApi, setSearchTerm } = props;
  const [order, setOrder] = React.useState(DEFAULT_ORDER);
  const [orderBy, setOrderBy] = React.useState(DEFAULT_ORDER_BY);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [visibleRows, setVisibleRows] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(DEFAULT_ROWS_PER_PAGE);
  const [paddingHeight, setPaddingHeight] = React.useState(0);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [seletedViewRow, setSelectedViewRow] = React.useState({});
  const [selectedEditRow, setSelectedEditRow] = React.useState({});
  const [rowValues, setRowValues] = React.useState(null);

  const dispatch = useDispatch();

  React.useEffect(() => {
    let rowsOnMount = stableSort(
      markets,
      getComparator(DEFAULT_ORDER, DEFAULT_ORDER_BY),
    );

    rowsOnMount = rowsOnMount.slice(
      0 * DEFAULT_ROWS_PER_PAGE,
      0 * DEFAULT_ROWS_PER_PAGE + DEFAULT_ROWS_PER_PAGE,
    );

    setVisibleRows(rowsOnMount);
  }, []);

  const handleRequestSort = React.useCallback(
    (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      const toggledOrder = isAsc ? 'desc' : 'asc';
      setOrder(toggledOrder);
      setOrderBy(property);

      const sortedRows = stableSort(markets, getComparator(toggledOrder, property));
      const updatedRows = sortedRows.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      );

      setVisibleRows(updatedRows);
    },
    [order, orderBy, page, rowsPerPage],
  );

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = markets.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const classes1 = useStyles();
  // -------------------------------------------------------

  const handleDeleteRow = (row) => {
    setSelectedRow(row);
    dispatch(modalActions.toggleDeleteMarketModalHandler());
  }

  const handleViewRow = (row) => {
    setSelectedViewRow(row);
    dispatch(modalActions.toggleViewMarketModalHandler());

  }
  const handleEditRow = (row) => {
    setSelectedEditRow(row);
    setRowValues(row);
    dispatch(modalActions.toggleEditMarketModalHandler());
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer className={classes1.table}>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={markets.length}
            />
            <TableBody>
              {
                stableSort(markets, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.name)}
                      role=""
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                      >
                        {row.name}
                      </TableCell>
                      <TableCell align="left">{row.date}</TableCell>
                      <TableCell align="left">{row.time}</TableCell>
                      <TableCell align="left">{row.uploadedBy}</TableCell>
                      <TableCell align="left" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <VisibilityIcon onClick={() => handleViewRow(row)} />
                        <EditIcon onClick={() => handleEditRow(row)} />
                        <DeleteIcon
                          onClick={() => handleDeleteRow(row)} />
                      </TableCell>
                    </TableRow>
                  );
                })
              }
              {paddingHeight > 0 && (
                <TableRow
                  style={{
                    height: paddingHeight,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={markets.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <ViewMarketModalOpen selectedViewRow={seletedViewRow} setSelectedViewRow={setSelectedViewRow} />
      <EditMarketModalOpen setDataApi={setDataApi} dataApi={dataApi} setSearchTerm={setSearchTerm} selectedEditRow={selectedEditRow} setSelectedEditRow={setSelectedEditRow} markets={markets} setMarkets={setMarkets} marketsId={marketsId} rowValues={rowValues} setRowValues={setRowValues} />
      <DeleteMarketModalOpen setDataApi={setDataApi} dataApi={dataApi} setSearchTerm={setSearchTerm} selectedRow={selectedRow} setSelectedRow={setSelectedRow} markets={markets} setMarkets={setMarkets} marketsId={marketsId} />
    </Box>
  );
}