import Navbar from "../HomePage/Navbar/Navbar"
import Sidebar from "../Sidebar/Sidebar"

const CommonLayout = ({children})=>{
    return(
        <>
         <Navbar/>
         <Sidebar/>
         {children}
        </>
    )
}
export default CommonLayout;