import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Logo from '../../assets/logo/FaceGenie logo.png'
import SigninFormComponent from "../SigninForm/SigninFormComponents";
import '../../styles/Signin.css'
const SigninPage = () => {
    
    return(
         <React.Fragment>
            <div>
            <img src="https://www.foodfindsasia.com/wp-content/uploads/2023/03/mishaal-zahed-w8X2pbw4O2g-unsplash.jpg" alt="preview" className="main-image"/>
            </div>
            <div className="container" fluid="sm">
                <Row className="mb-4">
                    <Col className="col-12" style={{textAlign: 'center'}}>
                        <img src={Logo} alt="FACEGENIE" width={138} />
                    </Col>
                </Row>
                <Row>
                    <Col> 
                    <p className="paragraph" style={{textAlign:'center', marginBottom: '1.5rem'}}>Freezer Analytics Template Management</p>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12">
                        <SigninFormComponent/>
                    </Col>
                </Row>
            </div>
         </React.Fragment>
    )
}
export default SigninPage