import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {getFirestore} from "firebase/firestore";
import {getStorage} from 'firebase/storage';
 
const firebaseConfig = {
  apiKey: "AIzaSyDlIshq5VyKXZti4c_iTonGDiTaHgrKoSA",
  authDomain: "mm-product-occupancy-analytics.firebaseapp.com",
  projectId: "mm-product-occupancy-analytics",
  storageBucket: "mm-product-occupancy-analytics.appspot.com",
  messagingSenderId: "191142787080",
  appId: "1:191142787080:web:cd534e8fcd3bd52d4239bd",
  measurementId: "G-W06ENLWSH9"
  
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage }








 