import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from '../store/modal'
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from 'yup';
import {db} from '../Firebase/Firebase';
import {Timestamp, doc, getDoc, updateDoc, } from "firebase/firestore";
import '../../styles/Signin.css';
import { toast } from "react-toastify";
import { v4 } from "uuid";
const formSchema = Yup.object({
      brandName: Yup.string().trim().required('Market name is required')
})
const AlertAddMarketModal = (props)=>{

    const {markets, setMarkets, setDataApi} = props;
    const dispatch = useDispatch();
    const isAddMarketModalOpen = useSelector(state => state.modal.isAddMarketModalOpen);
    const [uploadedBy, setUploadedBy] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const  handleMarket = async (newArray)=>{
        const marketsRef = doc(db, 'masters', 'markets');
              const marketsDoc =  await getDoc(marketsRef);
              if(marketsDoc.exists()){
                await updateDoc(marketsRef, {
                      MARKETS: newArray
                })
                setMarkets(newArray);
                setDataApi(newArray);
              }
              else{
                console.log('document exists');
              }
              
       }
  
    const {values, handleChange, handleSubmit, errors} = useFormik({
          initialValues: {
              brandName: ''
          },
          validationSchema: formSchema,
          onSubmit: (values, action)=>{
            if(markets.some(element=> element.name === values.brandName)){
                alert('Please enter unique market name ');
                action.resetForm();
                return;
            }
            else{
                const dataStructure = {
                      name: values.brandName,
                      uploadedBy: uploadedBy,
                      createdAt: Timestamp.fromDate(new Date()),
                      updatedAt: Timestamp.fromDate(new Date()),
                      id: v4()
                }
                const newArray = [...markets, dataStructure]
                setMarkets(newArray);
                // setDataApi(newArray);
                handleMarket(newArray);
                action.resetForm();
                dispatch(modalActions.toggleAddMarketModalHandler())
                toast.success('Market Added Successfully');
            }
          }
    })
 
    useEffect(()=>{
      const getName = localStorage.getItem("Display Name");
            setUploadedBy(getName);
            
    },[])
    
    const handleOnHide = ()=>{
      dispatch(modalActions.toggleAddMarketModalHandler());
      errors.brandName = '';
    }
    
    return(
        <Modal 
            size="lg"
            centered
            show={isAddMarketModalOpen} 
            onHide={handleOnHide}
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body style={{padding: '2rem'}}>
                <div>
                  <p className="add-template">Add Market</p>
                </div>
                <div style={{marginBottom:'2rem'}}>
                   <form onSubmit={handleSubmit}>
                        <label className="template-label">Market Name
                            <input type="name" className="template-name" name="brandName" autoComplete="off" value={values.brandName} onChange={handleChange} placeholder="Enter market name"/>
                            {errors.brandName ? <p style={{marginLeft:'130px'}}>{errors.brandName}</p>: null}
                        </label>
                        <Modal.Footer>
                            <div  className="submit-button">
                                <button type="submit" className="button">
                                    {isLoading ? 'Adding Data ..........' : 'Submit'}
                                </button>
                            </div>
                        </Modal.Footer>
                   </form>
               </div>
            </Modal.Body>
        </Modal>
         
    )
}
export default AlertAddMarketModal;