import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "../store/modal";
import { db } from "../Firebase/Firebase";
import { doc, updateDoc } from "firebase/firestore";
import { toast } from "react-toastify";

const DeleteTypeModalOpen = (props)=>{
    const isDeleteTypeModalOpen = useSelector((state)=> state.modal.isDeleteTypeModalOpen);
    const dispatch = useDispatch();
    const {selectedRow, setSelectedRow, setType, typeId, setDataApi, dataApi, setSearchTerm} = props;
     
    const handleDeleteBrand = async()=>{
             const filterData = dataApi.filter((item)=> item.name !== selectedRow.name)
             try{
                await updateDoc(doc(db, "masters", typeId ),{
                     TYPES: filterData
                });
                dispatch(modalActions.toggleDeleteTypeModalHandler());
                setType(filterData);
                setDataApi(filterData)
                setSelectedRow(null);
                setSearchTerm('');
                toast.success("Delete Successfully");
             }
             catch(error){
                console.log(error);
             }
       }

    return (
        <>
             <Modal
             centered
             show={isDeleteTypeModalOpen}
             onHide={()=> dispatch(modalActions.toggleDeleteTypeModalHandler())}
           >
             <Modal.Header closeButton></Modal.Header>
             <Modal.Body style={{textAlign:'center'}}>
                Are you sure want to delete ?
             </Modal.Body>
             <Modal.Footer>
               <Button variant="primary" 
               onClick={handleDeleteBrand}
               >Confirm</Button>
               <Button variant="primary" 
               onClick={()=> dispatch(modalActions.toggleDeleteTypeModalHandler())}
               >Cancel</Button>
             </Modal.Footer>
           </Modal>
          
        </>
    )
}
export default DeleteTypeModalOpen;