import React, { useEffect, useState } from "react";
import {InputGroup } from "react-bootstrap";
import {FiSearch} from "react-icons/fi";
import '../../../styles/Signin.css';
import ManageTemplateTable from "./ManageTemplatesTable";
import AlertAddTemplatesModal from "../../Modals/AddTemplatesModal";
import { useDispatch } from "react-redux";
import { modalActions } from '../../store/modal';
import {collection, getDocs  } from "firebase/firestore";
import { db } from "../../Firebase/Firebase";
import '../../../styles/Signin.css';

const ManageTemplates = ()=>{
    const dispatch = useDispatch();
    const [templates, setTemplates]  = useState([])
    const [dataApi, setDataApi] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [productData, setProductData] = useState([]);
    const [productDocumentId, setProductDocumentId ] = useState([]);

    const getTemplates = async ()=>{
      try{
        const querySnapshot =  await  getDocs(collection(db, "templates"));
        const data = [];
        querySnapshot.forEach(doc => {
            data.push({...doc.data(), id: doc.id});
        })
        setTemplates(data);
        setDataApi(data);

      }catch(error){
        console.log(error);
      }
    }
    
    useEffect(()=>{
        getTemplates();
    },[])

    const getProductData = async ()=>{

      try{
        const querySnapshot =  await  getDocs(collection(db, "products"));
        const data = [];
        const dataDocumentId = [];
        
        querySnapshot.forEach(doc => {
            data.push({...doc.data(), id: doc.id});
            dataDocumentId.push(doc.id);

             
        })
        setProductData(data);
        setProductDocumentId(dataDocumentId);

      }catch(error){
        console.log(error);
      }
    }
    
    useEffect(()=>{
        getProductData();
    },[])

    const handleSearch = (e)=>{
          if(e.target.value === ''){
            setTemplates(dataApi);
          }
          else{

              const lowerCaseSearchTerm = searchTerm.trim().toLowerCase();
              
              const filterData = dataApi.filter((item)=> {
                const date = new Date(item.createdAt.seconds * 1000 + item.createdAt.nanoseconds / 1000000);
                const formattedDate = date.toLocaleDateString().split('/').join('-');
                const formattedTime = date.toLocaleTimeString('en-US', { hour12: false });
                
                      let templateName = item.name || '';
                      let typeTemplate = item.product || ''
                      let timeTemplate = formattedTime || ''
                      let dateTemplate = formattedDate || ''
                      let uploaded_by = item.uploadedBy || ''
                      let templateStatus = item.status.toString();
                  
                  return (
                    templateName.toLowerCase().includes(lowerCaseSearchTerm) ||
                    typeTemplate.toLowerCase().includes(lowerCaseSearchTerm) ||
                    dateTemplate.toLowerCase().includes(lowerCaseSearchTerm) ||
                    timeTemplate.toLowerCase().includes(lowerCaseSearchTerm) ||
                    templateStatus.toLowerCase().includes(lowerCaseSearchTerm) ||
                    uploaded_by.toLowerCase().includes(lowerCaseSearchTerm)
                  )
                    
              });

                setTemplates(filterData);

          }
          setSearchTerm(e.target.value);
    }

    return (
        <React.Fragment>
            <div
            style={{
               position:'absolute',
               top:110,
               left:240,
               width:'82%',
               height:'100%',
            }} 
            
            >
                  
                  <div className="manage-templates-header"  style={{display: 'flex',justifyContent: 'space-between', marginBottom:'2rem', alignItems:'center'}}>
                    <span className="manage-templates-text">Manage Template</span>
                    <span className="add-templates-header" onClick={()=> dispatch(modalActions.toggleAddTemplatesModalHandler())} >Add Template <span className="plus">+</span></span>
                  </div>
              
                  <InputGroup>
                    

                    <input type="search" id="input-placeholder" autoComplete="off" placeholder="Search by template name, product name, date, time, uploaded by" value={searchTerm} 
                    onChange={(e)=>handleSearch(e)} 
                    className="form-control" style={{padding: '10px',

                    background: '#FFFFFF',
                    boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.2)'}}/>
                    <FiSearch
                     style={{
                          position:'absolute',
                          top:'50%',
                          left:'10px',
                          transform: 'translateY(-50%)',
                          pointerEvents: 'cursor'
                     }}

                    />
                  </InputGroup>
              
              <div style={{marginTop:'2rem'}}>
                  <ManageTemplateTable templates={templates} setTemplates={setTemplates} setDataApi={setDataApi}  dataApi={dataApi} setSearchTerm={setSearchTerm}/>
              </div>
            
            </div>
            <AlertAddTemplatesModal templates={templates} setTemplates={setTemplates} setProductData={setProductData} productData={productData} productDocumentId={productDocumentId} setProductDocumentId={setProductDocumentId} setDataApi={setDataApi} dataApi={dataApi} setSearchTerm={setSearchTerm}/>
        </React.Fragment>
    )
}
export  default ManageTemplates;
 