import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const SidebarLink = styled(Link)`
display: flex;
flex-direction: row;
align-items: flex-start;
padding: 12px 24px;
gap: 70px;
color: rgb(61, 60, 60);
width: 220px;
height: 48px;
margin-left: 55px;
background: #FFFCFB;
border-radius: 5px;
/* Inside auto layout */
text-decoration: none;

// flex: none;
order: 3;
flex-grow: 0;

&:hover {
  // background: #F33823;
  cursor: pointer;
  text-decoration: none;
  color: #0d6efd;

}
`;

const SidebarLabel = styled.span`
  margin-left: 10px;
`;

const DropdownLink = styled(Link)`
display: flex;
flex-direction: row;
align-items: flex-start;
padding: 3px 30px;
gap: 16px;
text-decoration: none;
width: 220px;
height: 30px;
color: black;
margin-left: 55px;
// background: #F33823;
border-radius: 5px;
/* Inside auto layout */

// flex: none;
order: 3;
flex-grow: 0;
&:hover {
  background: #F33823;
  cursor: pointer;
  text-decoration: none;
  color: white;

}
`;

const SubMenu = ({ item }) => {
  const [subnav, setSubnav] = useState(false);

  const showSubnav = () => setSubnav(!subnav);

  return (
    <>
      <SidebarLink  onClick={ item.subNav && showSubnav }>
        <div style={{marginLeft:'-10px'}}>
     {item.icon}
          <SidebarLabel>{item.title}</SidebarLabel>
        </div>
        <div>
          { item.subNav && subnav 
            ? item.iconOpened 
            : item.subNav 
            ? item.iconClosed 
            : null}
        </div>
      </SidebarLink>
      {subnav &&
        item.subNav.map((item, index) => {
          return (
            <DropdownLink to={item.path} key={index}>
              {item.icon}
              <SidebarLabel>{item.title}</SidebarLabel>
            </DropdownLink>
          );
        })}
    </>
  );
};

export default SubMenu;
